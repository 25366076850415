.s-compare {
    padding: 30px 0;
    z-index: 1;
    background-color: #fff;
    &:after {
        content: '';
        width: 100%;
        position: absolute;
        left: 0;
        top: 170px;
        height: 270px;
        background-color: $blue;
        z-index: -1;
        @include media(414) {
            display: none;
        }
    }
    @include media(414) {
        padding: 10px 0 30px;
    }
    &.compare_one {
        .excellence__item {
            &:after {
                @include size(18px);
                background-color: transparent;
                border: 1px solid $orange2;
                @include media(414) {
                    @include size(10px);
                    background-color: $orange2;
                }
            }
        }
    }
}

.compare {
    margin-bottom: 45px;
    @include media(768) {
        margin-bottom: 65px;
    }
    &__title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 40px;
        @include media(414) {
            font-size: 18px;
            line-height: 21px;
            margin-bottom: 20px;
        }
    }
    &__img {
        position: relative;
        &:before,
        &:after {
            content: '';
            position: absolute;
            z-index: 2;
            border-radius: 4px;
            width: 100%;
            max-width: 370px;
            height: calc(100% - 46px);
            top: 50%;
            transform: translateY(-50%);
            @include media(768) {
                max-width: 330px;
            }
            @include media(414) {
                width: 50%;
                max-width: calc(50% - 15px);
                height: calc(100% + 20px);
            }
        }
        img {
            position: relative;
            width: 630px;
            max-width: 100%;
            border-radius: 10px;
            border: 2px solid $blue;
            @include media(414)  {
                border-radius: 0;
            }
            &:before {  
                content: "";
                display: block;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                height: calc(100% + 6px);
                width: 630px;
                background-color: #e6e6e6;
                border-radius: 5px;
            }
            &:after {  
                content: "\1F937" " " attr(alt);
                display: block;
                font-size: 16px;
                color: rgb(100, 100, 100);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                text-align: center;
            }
        }
        &:before {
            left: -60px;
            background: rgba(151, 213, 200, 0.15);
            border: 3px solid rgba(151, 213, 200, 0.7);
            @include media(768) {
                left: -20px;
            }
            @include media(414) {
                left: 12px;
                border-width: 2px;
            }
        }
        &:after {
            right: -60px;
            background: rgba(152, 214, 236, 0.04);
            border: 3px solid rgba(152, 214, 236, 0.7);
            @include media(768) {
                right: -20px;
            }
            @include media(414) {
                right: 12px;
                border-width: 2px;
            }
        }
    }
    &__col {
        text-align: center;
        color: $white;
        letter-spacing: 0.03em;
        p {
            font-size: 17px;
            line-height: 25px;
            @include media(414) {
                font-size: 15px;
                line-height: 17px;
            }
        }
        span {
            font-size: 14px;
            font-weight: 500;
            @include media(414) {
                font-size: 11px;
            }
        }
        span, p {
            @include media(768) {
                color: $blue;
            }
        }
        &:last-child,
        &:first-child {
            @include media(768) {
                position: absolute;
                bottom: -50px;
            }
        }
        &:last-child {
            right: 15%;
            @include media(414) {
                right: 0;
            }
        }
        &:first-child {
            left: 15%;
            @include media(414) {
                left: 0;
            }
        }
        &:nth-child(2) {
            @include media(414) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }
        
    }
    &__list {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(768) {
            justify-content: center;
        }
    }
}

.excellence {
    display: flex;
    justify-content: center;
    @include media(414) {
        justify-content: space-between;
    }
    @include media(320) {
        margin-left: -10px;
        margin-right: -10px;
    }
    & > * {
        &:not(:last-child) {
            margin-right: 100px;
            @include media(414) {
                margin-right: 0;
            }
        }
    }
    &__item {
        position: relative;
        font-size: 17px;
        line-height: 21px;
        color: $blue;
        text-align: right;
        margin-bottom: 25px;
        padding-right: 30px;
        @include media(768) {
            margin-bottom: 15px;
        }
        @include media(414) {
            font-size: 12px;
            line-height: 14px;
        }
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            @include size(14px);
            background-color: #F4B955;
            border-radius: 50%;
            @include media(414) {
                @include size(10px);
            }
        }
    }
    &__col {
        flex: 0 0 auto;
        @include media(768) {
            flex: 0 0 50%;
        }
        @include media(414) {
            max-width: 142px;
        }
        &:first-child,
        &:last-child {
            .excellence__item {
                span {
                    &:first-child {
                        @include media(414) {
                            display: none;
                        }
                    }
                    &:last-child {
                        display: none;
                        @include media(414) {
                            display: block;
                        }
                    }
                }
            }
        }
        &:first-child {
            .excellence__item {
                @include media(414) {
                    padding-right: 17px;
                }
            }
        }
        &:last-child {
            .excellence__item {
                padding-left: 30px;
                text-align: left;
                padding-right: 0;
                @include media(414) {
                    padding-left: 17px;
                }
                &:after {
                    left: 0;
                    right: auto;
                }
                
            }
        }
        
    }
}