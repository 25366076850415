.s-services {
    padding: 50px 0;
    background-color: $blue;
    @include media(370) {
        padding: 10px 0 20px;
    }
}

.services {
    position: relative;
    &__psev {
        display: none;
        position: absolute;
        z-index: 1;
        pointer-events: none;
        width: 100%;
        top: 34%;
        left: 0;
        @include media(768) {
            display: block;
        }
        @include media(370) {
            display: none;
        }
        &_item {
            position: relative;
            display: flex;
            width: 100%;
            margin-bottom: 490px;
            justify-content: space-between;
            align-items: center;
            @include media(414) {
                margin-bottom: 410px;
            }
            &:after,
            &:before {
                content: '';
                position: absolute;
                background: #91B7C6;
                border-radius: 6px;
                transform: rotate(45deg);
                @include size(140px);
                @include media(414) {
                    @include size(110px);
                }
            }
            &:after {
                right: -85px;
            }
            &:before {
                left: -85px;
            }
        }
    }
    &__block {
        position: relative;
        top: -120px;
        @include size(680px);
        margin: auto;
        transform: rotate(45deg);
        @include media(768) {
            display: flex;
            width: auto;
            height: auto;
            top: -50px;
            flex-direction: column;
            align-items: center;
            transform: rotate(0);
        }
        @include media(370) {
            top: 0;
        }
    }
    &__item {
        position: relative;
        display: flex;
        @include size(140px);
        background: #F5F5F5;
        border-radius: 6px;
        color: $white;
        margin: 15px;
        span {
            position: absolute;
            width: 100%;
            left: 58%;
            top: calc(50% - 15px);
            transform: rotate(-90deg);
            font-size: 15px;
            line-height: 20px;
            @include media(414) {
                font-size: 12px;
                line-height: 18px;
            }
            @include media(370) {
                transform: rotate(0) translateX(-50%);
                left: 50%;
                top: 100%;
                text-align: center;
            }
        }
        img {
            max-width: 90px;
            margin: auto;
            transform: rotate(-45deg)!important;
            @include media(414) {
                max-width: 70px;
            }
            @include media(370) {
                transform: rotate(0);
            }
        }
        @include media(414) {
            @include size(110px);
            margin: 0 12px 10px;
        }
    }
    &__col {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        max-width: 340px;
        height: 340px;
        h2 {
            position: absolute;
            width: 100%;
            text-align: center;
            top: 45%;
            left: 45%;
            color: $white;
            transform: rotate(-45deg) translate(-32%, -50%);
            @include media(768) {
                left: 0;
                top: 35px;
                transform: translate(0, 0);
            }
            @include media(370) {
                font-size: 18px;
                top: 20px;
            }
        }
        &:not(:first-child):not(:last-child) {
            @include media(768) {
                margin-bottom: 130px;
            }
            @include media(370) {
                margin-bottom: 5px;
            }
        }

        &:first-child {
            top: 0;
            left: 0;
            @include media(768) {
                transform: rotate(0);
                height: 130px;
            }
            @include media(370) {
                transform: rotate(0);
                height: 60px;
            }
        }
        &:nth-child(2) {
            right: 0;
            top: 0;
        }
        &:nth-child(3) {
            left: 0;
            bottom: 0;
        }
        &:last-child {
            right: 0;
            bottom: 0;
        }
        
        @include media(768) {
            position: relative;
            transform: rotate(45deg);
        }
        @include media(414) {
            max-width: 270px;
            height: 260px;
        }
        @include media(370) {
            transform: rotate(0);
            max-width: 280px;
            height: 270px;

        }
    }
}