.s-product {
    padding: 50px 0;
    background-color: #fff;
    @include media(768) {
        padding: 50px 0 20px;
    }
    @include media(414) {
        padding: 20px 0;
    }
}

.product {
    &__title {
        margin-bottom: 18px;
        font-size: 24px;
        line-height: 29px;
        @include media(414) {
            font-size: 20px;
            line-height: 24px;
            margin-bottom: 12px;
        }
    }
    h3 {
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        color: $blue;
        margin-bottom: 32px;
        @include media(414) {
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 24px;
        }
    }
    &__list {
        max-width: 840px;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        @include size(170px);
        margin: 20px;
        @include media(414) {
            @include size(120px);
        }
        @include media(350) {
            margin: 10px;
        }
        &:nth-child(3) {
            .product__square {
                img {
                    @include media(414) {
                        max-width: 50px;
                    }
                }
            }
        }
        &:nth-child(5) {
            .product__square {
                img {
                    @include media(414) {
                        max-width: 80px;
                    }
                }
            }
        }
    }
    &__square {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: rotate(45deg);
        @include size(120px);
        background-color: $blue;
        color: $blue;
        border: 2px solid $orange;
        border-radius: 6px;
        span {
            position: absolute;
            width: 101%;
            transform: rotate(-90deg);
            top: calc(50% - 15px);
            left: 60%;
            font-size: 15px;
            line-height: 20px;
            @include media(414) {
                font-size: 12px;
            }
            @include media(350) {
                transform: rotate(0);
                top: 102%;
                left: 0;
                text-align: center;
            }
        }
        img {
            max-width: 100%;
            transform: rotate(-45deg)!important;
            @include media(414) {
                max-width: 60px;
            }
            @include media(350) {
                transform: rotate(0);
            }
        }
        @include media(414) {
            @include size(100px);
        }
        @include media(350) {
            transform: rotate(0);
        }
    }
}