.s-luminated {
    padding: 40px 0;
    @include media(414) {
        padding: 20px 0;
    }
}
.luminated {
    &__inner {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 35px;
        @include media(768) {
            flex-direction: column-reverse;
            align-items: center;
            margin-bottom: 20px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            background-color: $orange;
            height: 170px;
            width: 1000%;
            left: 50%;
            top: 46%;
            transform: translate(-50%,-50%);
            @include media(768) {
                display: none;
            }
        }
    }
    &__title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 40px;
        @include media(768) {
            margin-bottom: 20px;
        }
        @include media(414) {
            font-size: 18px;
            line-height: 24px;
        }
    }
    &__col {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include media(768) {
            justify-content: center;
        }
        p {
            font-weight: 500;
            font-size: 17px;
            user-select: none;
            line-height: 21px;
            color: $gray;
            @include media(768) {
                text-align: center;
            }
            @include media(414) {
                text-align: left;
                font-size: 14px;
                line-height: 19px;
                margin-bottom: 20px;
            }
            &:last-child {
                @include media(414) {
                    margin-bottom: 0;
                }
            }
        }
        &:first-child {
            .luminated__img {
                &:nth-child(2) {
                    margin-right: 25px;
                    @include media(414) {
                        margin-right: 0;
                    }
                    span {
                        background-color: $blue;
                        border-top: 1px solid $orange;
                    }
                }
                span {
                    background-color: $orange;
                    border-top: 1px solid $blue;
                }
                img {
                    @include media(414) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                @include media(768) {
                    margin: 20px 0;
                }
                @include media(414) {
                    width: 100%;
                    height: 250px;
                    margin: 0 0 20px 0;
                }
            }
        }
        &:last-child {
            img {
                @include media(768) {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }
            @include media(768) {
                max-width: 350px;
                margin-bottom: 20px;
            }
            .luminated__img {
                &:after {
                    height: 300px;
                    @include media(414) {
                        display: none;
                    }
                }
                @include media(414) {
                    height: 250px;
                }
            }
            @include media(414) {
                max-width: 100%;
            }
        }
        &:not(:last-child) {
            margin-right: 25px; 
            @include media(768) {
                margin-right: 0;
            }
        }
    }
    &__img {
        position: relative;
        z-index: 1;
        border-radius: 10px;
        border: 1px solid $gray;
        &:after {
            content: '';
            display: none;
            position: absolute;
            z-index: -1;
            background-color: $blue;
            width: 1000%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            @include media(768) {
                display: block;
            }
        }
        img {
            border-radius: 7px;
            max-width: 320px;
            @include media(414) {
                max-width: 100%;
            }
        }
        span {
            display: none;
            font-size: 14px;
            padding: 10px 0;
            border-radius: 0 0 7px 7px;
            width: 100%;
            letter-spacing: 0.03em;
            color: $white;
            position: absolute;
            left: 0;
            bottom: 0;
            text-align: center;
            @include media(768) {
                display: block;
            }
        }
    }
    &__list {
        position: relative;
        display: flex;
        margin: 0 -12.5px 40px -12.5px;
        @include media(768) {
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        @include media(414) {
            margin: 0 -5px 25px -5px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            background-color: $orange;
            height: 170px;
            width: 1000%;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
        }
    }
    &__item {
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid $gray;
        margin: 0 12.5px;
        img {
            height: 250px;
            width: 100%;
            object-fit: cover;
        }
        &:nth-last-child(-n+2) {
            @include media(768) {
                margin-bottom: 0;
            }
        }
        @include media(768) {
            width: calc(50% - 25px);
            max-width: calc(50% - 25px);
            margin-bottom: 25px;
            height: 220px;
        }
        @include media(414) {
            width: calc(50% - 10px);
            max-width: calc(50% - 10px);
            height: 200px;
            margin: 0 5px 10px;
        }
    }
    .Button {
        border-color: $orange;
        @include media(350) {
            padding: 10px 12px;
        }
    }
}
