@mixin size($a,$b: $a) {
  width: $a;
  height: $b;
}

@mixin center() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%)
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}

@mixin links ($link, $visited, $hover, $active) {
    & {
        color: $link;
        &:visited {
            color: $visited;
        }
        &:hover {
            color: $hover;
        }
        &:active, &:focus {
            color: $active;
        }
    }
}

@mixin media($size) {
    @media screen and (max-width: $size+'px') {
        @content;
    }
}
