.s-triplex {
    padding: 30px 0 70px;
    @include media(414) {
        padding: 30px 0 20px;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 26%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        height: 700px;
        background-color: $gray;
        @include media(414) {
            height: 520px;
            top: 28%;
        }
        @include media(320) {
            top: 27%;
        }
    }
}

.triplex {
    &__title {
        @include media(768) {
            margin-bottom: 20px;
        }
    }
    &__banner {
        width: 100%;
        max-width: 700px;
        height: 400px;
        margin: 0 auto;
        @include media(768) {
            height: auto;
        }
    }
    p {
        font-size: 19px;
        line-height: 23px;
        text-align: center;
        color: #396576;
        user-select: none;
        @include media(414) {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
        }
    }
    &__before {
        position: absolute;
        right: -10px;
        top: -90px;
        display: none;
        @include media(768) {
            display: block;
        }
        @include media(320) {
            display: none;
        }
    }
    &-before,
    &-after {
        position: absolute;
        @include media(768) {
            display: none;
        }
    }
    &-before {
        left: 0;
        top: 0;
    }
    &-after {
        right: 0;
        top: 40%;
    }
    img {
        border-radius: 10px;
        max-width: 100%;
    }
    &__list {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        @include media(768) {
            margin: 20px 0;
        }
    }
    &__item {
        max-width: 280px;
        img {
            max-width: 100%;
            width: 100%;
            height: 190px;
            object-fit: cover;
            @include media(600) {
                height: 150px;
            }
            @include media(414) {
                height: 100px;
            }
            @include media(320) {
                height: 80px;
            }
        }
        &:not(:last-child) {
            @include media(768) {
                margin-right: 15px;
            }
        }
    }
    &__info {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 60px 0 80px;
        @include media(768) {
            margin: 60px 0 40px;
            padding-left: 24px;
        }
        @include media(414) {
            padding-left: 5px;
            margin: 60px 0 10px;
        }
        @include media(320) {
            margin: 40px 0 10px;
        }
        &_item {
            position: relative;
            display: flex;
            align-items: center;
            &:after {
                content: '';
                position: absolute;
                left: -40px;
                bottom: 0;
                @include size(16px);
                background-color: #D6E8EF;
                @include media(768) {
                    @include size(14px);
                    left: -24px;
                    bottom: calc(100% - 14px);
                }
                @include media(414) {
                    @include size(7px);
                    left: -12px;
                }
            }
            img {
                width: 100%;
                border: 2px solid $gray;
                margin-right: 25px;
                max-width: 280px;
                height: 190px;
                object-fit: cover;
                @include media(414) {
                    max-width: 125px;
                    height: 85px;
                    margin-right: 10px;
                }
            }
            span {
                font-size: 19px;
                line-height: 23px;
                color: #396576;
                user-select: none;
                @include media(414) {
                    font-size: 12px;
                    line-height: 15px;
                }
            }
            &:not(:last-child) {
                margin-bottom: 30px;
                @include media(414) {
                    margin-bottom: 15px;
                }
            }
        }
    }
    &__move {
        h3 {
            font-size: 22px;
            line-height: 27px;
            color: $gray;
            margin-bottom: 30px;
            @include media(414) {
                font-size: 18px;
                text-align: center;
                line-height: 24px;
                margin-bottom: 18px;
            }
            @include media(320) {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 15px;
            }
        }
        p {
            max-width: 700px;
            margin: auto;
            font-size: 19px;
            line-height: 23px;
            text-align: center;
            color: #396576;
            @include media(414) {
                font-size: 14px;
                line-height: 19px;
            }
        }
        &_list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -20px;
            margin-right: -20px;
            margin-bottom: 40px;
            @include media(768) {
                margin-bottom: 20px;
            } 
            @include media(414) {
                margin-left: -10px;
                margin-right: -10px;
            }
            @include media(320) {
                margin-left: -5px;
                margin-right: -5px;
            }
        }
        &_item {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            border: 2px solid $gray;
            flex: 0 0 calc(33.33% - 40px);
            max-width: calc(33.33% - 40px);
            height: 200px;
            margin: 0 20px;
            @include media(768) {
                height: 170px;
            }
            @include media(414) {
                margin: 0 10px;
                flex: 0 0 calc(33.33% - 20px);
                max-width: calc(33.33% - 20px);
                height: 100px;
            }
            @include media(320) {
                margin: 0 5px;
                flex: 0 0 calc(33.33% - 10px);
                max-width: calc(33.33% - 10px);
                height: 80px;
            }
            span {
                font-size: 20px;
                line-height: 24px;
                @include media(320) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }
    }
}

p.text-white {
    color: $white;
}