// Colors
$white:  #ffffff;
$black:  #000000;
$blue:   #7EA0AD;
$orange: #F4B955;
$orange2:#F5BA54;
$gray:   #85A8B6;
$gray2:  #57808F;
$gray3:  #91B7C6;

$ph-bg:                   #fff !default;
$ph-color:                #ced4da !default;
$ph-border-radius:        2px !default;
$ph-gutter:               30px !default;
$ph-spacer:               15px !default;
$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: .8s !default;



// Sizes

$base-font-size: 16px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1024px;

// Fonts

$Roboto: 'Roboto',Arial, sans-serif;