@font-face {
 font-family: 'Montserrat-Regular';
 src: url('../fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2');
 src: url('../fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}

@font-face {
 font-family: 'Montserrat-SemiBold';
 src: url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2') format('woff2');
 src: url('../fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}

@font-face {
 font-family: 'Montserrat-Medium';
 src: url('../fonts/Montserrat-Medium/Montserrat-Medium.woff2') format('woff2');
 src: url('../fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff');
 font-weight: normal;
 font-style: normal;
}

