.mobile-menu {
    position: fixed;
    z-index: 999;
    right: -100%;
    transition: right .4s ease;
    top: 74px;
    text-align: center;
    padding: 28px 23px;
    border: 1px solid rgba(152, 214, 236, 0.7);
    background: rgb(238,246,249);
    border-radius: 50px 0px 0px 50px;
    @include media(768) {
        top: 56px;
        border-radius: 0;
        text-align: right;
        border-right: 0;
        border-top: 0;
        padding: 23px 13px;
    }
    &.active {
        right: 0;
    }
}

.m-menu {
    &__item {
        display: block;
        font-size: 14px;
        transition: all .3s linear;
        line-height: 17px;
        color: $blue;
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &:hover {
            color: #184e62;
        }
    }
}