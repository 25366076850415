.s-online  {
    padding: 50px 0 30px;
    @include media(768) {
        padding: 20px 0 15px;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        bottom: 0;
        left: 0;
        height: 270px;
        background-color: $blue;
        @include media(414) {
            height: 80px;
        }
    }
    &_five {
        &:after {
            @include media(414) {
                top: 0;
                height: 320px;
            }
        }
        .online__title {
            @include media(414) {
                color: $white;
                max-width: 100%;
            }
        }
    }
}

.online {
    &__title {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 32px;
        @include media(414) {
            font-size: 18px;
            line-height: 22px;
            max-width: 240px;
            margin: 0 auto 15px;
        }

    }
    &__btn {
        text-align: center;
    }
    &__list {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        @include media(414) {
            display: none;
        }
    }
    &__item {
        border: 2px solid $blue;
        border-radius: 10px;
        overflow: hidden;
        margin: 0 20px;
        flex: 0 0 calc(33.33% - 40px);
        max-width: calc(33.33% - 40px);
        height: 230px;
        @include media(768) {
            height: auto;
        }
        @include media(414) {
            width: 100%;
            margin: 0;
        }
        &:nth-last-child(-n+2) {
            @include media(414) {
                flex: 0 0 calc(45% - 10px);
                max-width: calc(45% - 10px);
            }
        }
        &:first-child {
            @include media(414) {
                margin-bottom: 20px;
                flex: 0 0 90%;
                max-width: 100%;
            }
        }
        &:last-child {
            @include media(414) {
                margin-left: 20px;
            }
        }
        img {
            position: relative;
            width: 100%;
            object-fit: cover;
            max-width: 100%;
            &:before {  
                content: " ";
                display: block;
                @include center;
                height: 300px;
                width: 100%;
                background-color: #e6e6e6;
                border-radius: 5px;
            }
            &:after {  
                content: "\1F937" " " attr(alt);
                display: block;
                font-size: 16px;
                color: rgb(100, 100, 100);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 100%;
                text-align: center;
            }
        }
    }
    p {
        margin-bottom: 45px;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        user-select: none;
        color: $blue;
        @include media(414) {
            margin-bottom: 30px;
            font-size: 14px;
            line-height: 20px;
        }
    }
    &_five {
        .online__list {
            display: flex;
            @include media(414) {
                flex-wrap: wrap;
            }
        }
        p {
            @include media(414) {
                color: $white;
            }
        }
    }
}


.lazy-load--item {
    img {
      opacity: 0;
      display: none;
      animation: fadeIn .3s ease-in-out forwards;
      &.loaded {
        display: block;
      }
    }
}

  @keyframes fadeIn {
    from { opacity: 0; transform: scale(0.9); }
    to { opacity: 1; transform: scale(1);  }
  }