.loader {
    display: flex;
    align-items: center;
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-color: $white;
    position: absolute;
    animation: loader 1s ease-in-out infinite;
    animation-delay: 1s;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.loader:after, .loader:before {
    content: '';
    position: absolute;
    width: 5px;
    height: 20px;
    border-radius: 5px;
    background-color: $white;
    animation: loader 1s ease-in-out infinite; }
.loader:before {
    right: 10px;
    animation-delay: 0.9s; }
.loader:after {
    left: 10px;
    animation-delay: 1.1s; }
  
@keyframes loader {
    0%, 100% {
        height: 10px; }
    50% {
        height: 25px; } 
}