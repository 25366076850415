.calcInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    h1 {
        color: $gray;
        text-align: center;
        line-height: 45px;
        @include media(768) {
            font-size: 25px;
            line-height: 32px;
        }
        @include media(414) {
            font-size: 18px;
            line-height: 24px;
        }
    }
}