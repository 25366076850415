.s-perfect {
    background-color: $blue;
    padding-bottom: 60px;
    border-top: 2px solid $orange;
    @include media(414) {
        padding-bottom: 20px;
        border-top: none;
    }
    p {
        font-size: 19px;
        line-height: 28px;
        text-align: center;
        color: $white;
        font-weight: 400;
        @include media(768) {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.perfect {
    margin-bottom: 40px;
    @include media(414) {
        margin-bottom: 0;
    }
    &__title {
        padding: 35px 20px 25px 20px;
        border: 2px solid $orange;
        max-width: 520px;
        margin: -2px auto 40px;
        border-radius: 0 0 10px 10px;
        @include media(768) {
            padding: 20px;
        }
        @include media(414) {
            max-width: 280px;
            padding: 20px 0 0;
            margin-bottom: 20px;
            border: none;
        }
    }
    &__text {
        margin-left: 25px;
        @include media(414) {
            margin-left: 0;
            margin: auto;
        }
        p {
            text-align: left;
        }
    }
    &__line {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 1;
        width: 100%;
        height: 180px;
        margin-bottom: 25px;
        @include media(414) {
            flex-wrap: wrap;
            height: auto;
        }
        &:last-child {
            @include media(414) {
                margin-bottom: 10px;
            }
        }
        &:nth-child(3) {
            .perfect__item {
                &:not(:last-child) {
                    margin-right: 70px;
                    @include media(768) {
                        margin-right: 20px;
                    }
                    @include media(414) {
                        margin-right: auto;
                    }
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            top: 50%;
            left: -55px;
            transform: translateY(-50%);
            width: 300%;
            border-radius: 10px;
            border: 2px solid $orange;
            height: 80px;
            @include media(414) {
                display: none;
            }
        }
        &:before {
            content: '';
            position: absolute;
            background-color: $orange;
            top: 50%;
            left: -30px;
            transform: translateY(-50%);
            @include size(16px);
            border-radius: 3px;
            @include media(768) {
                display: none;
            }
            
        }
        p {
            color: $white;
            font-size: 19px;
            line-height: 23px;
            font-weight: 400;
            @include media(768) {
                font-size: 16px;
            }
            @include media(414) {
                font-size: 12px;
                line-height: 16px;
                text-align: center;
            }
        }
    }
    &__item {
        position: relative;
        width: 280px;
        height: 180px;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $blue;
        border: 2px solid $orange;
        &:after {
            content: '';
            position: absolute;
            display: none;
            z-index: -1;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            width: 200%;
            border: 2px solid #F4B955;
            border-left: none;
            border-right: none;
            height: 80px;
            @include media(414) {
                display: block;
            }
        }
        @include media(414) {
            margin: 0 auto 10px;
        }
        &_icon {
            position: absolute;
            z-index: 1;
            opacity: .7;
            left: 50%;
            top: 50%;
            pointer-events: none;
            @include size(60px);
            transition: all .3s;
            border: 1px solid $white;
            border-radius: 50%;
            transform: translate(-50%,-50%);
        }
        &_play {
            @include size(30px);
            fill: $white;
            position: absolute;
            transform: translate(-50%,-50%) scale(1);
            left: 55%;
            top: 50%;
            opacity: .7;
            transition: all .3s;
            pointer-events: none;
            
            
        }
        video {
            outline: none;
        }
        a {
            position: relative;
            display: block;
            height: 100%;
        }
        img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
}


.video-hover {
    &:hover {
        .perfect__item_play {
            fill: #F4B955;
            opacity: 1;
            transform: translate(-50%,-50%) scale(1.1);
        }
        .perfect__item_icon {
            opacity: 1;
        }   
    }
}