@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

body {
  height: 100%;
  font-family: 'Montserrat',Arial,sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  &:after,
  &:before {
    content: '';
    height: 50vh;
    width: 100%;
    position: fixed;
    left: 0;
  }
  &:before {
    top: 0;
    transform: translateY(-100%);
  }
  &:after {
    bottom: 0;
    transform: translateY(100%);
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #F5F5F5;
  } 
  &::-webkit-scrollbar {
    width: 12px;
	  background-color: #F5F5F5;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #2980b9;
    background: linear-gradient(125deg, #2980b9, #34495e);
  }
}



.wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 { 
  margin: 0;
  font-weight: 500;
}

ul, li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a { text-decoration: none; }

p { margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; outline: none; }

textarea { resize: none; }

section { position: relative; }

img {
  user-select: none;
}

input,
select,
button { outline: none; border: none; background-color: transparent; }

*, *:before, *:after { box-sizing: border-box; }


.content { flex: 1 0 auto; overflow: hidden; }

.container {
  margin: 0 auto;
  padding: 0 15px;
  width: 100%;
  max-width: 1170px;
  &__triplex {
    max-width: 930px;
  }
  &__footer {
    max-width: 730px;
  }
  &__qwiz {
    max-width: 1030px;
  }


}

//Модули
@import "modules/modules";
