.s-basis {
    padding: 40px 0;
    @include media(414) {
        padding: 20px 0;
    }
}


.basis {
    position: relative;
    & > img {
        display: none;
        position: absolute;
        right: -15px;
        bottom: 50px;
        @include media(414) {
            display: block;
        }
        @include media(330) {
            display: none;
        }
    }
    &__list {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 100px;
        @include media(768) {
            justify-content: center;
        }
        @include media(414) {
            margin-bottom: 40px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: calc(50% - 20px);
            transform: translateX(-50%);
            top: 20px;
            border-radius: 5px;
            width: calc(100% + 100px);
            height: 180px;
            background-color: $gray;
        }
    }
    &__title {
        margin-bottom: 70px;
        @include media(414) {
            margin: 0 auto 20px;
            max-width: 240px;
        }
    }
    &__col {
        color: $gray;
        &:nth-child(2) {
            flex: 0 0 380px;
            margin-top: -30px;
            margin-left: -38px;
            @include media(414) {
                margin-top: 0;
                margin-left: -10px;
                margin-right: -10px;
                flex: 0 0 100%;
            }
        }
        &:not(:nth-child(2)) {
            @include media(768) {
                display: none;
            }
        }
        ul {
            li {
                position: relative;
                font-size: 17px;
                line-height: 21px;
                padding-left: 28px;
                user-select: none;
                @include media(768) {
                    font-size: 12px;
                    line-height: 16px;
                }
                @include media(335) {
                    font-size: 10px;
                    line-height: 14px;
                }
                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    @include size(20px);
                    border-radius: 50%;
                    background-color: $orange;
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                    @include media(340) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    &__img {
        margin-bottom: 40px;
        img {
            max-width: 263px;
        }
        span {
            display: block;
            margin-top: 8px;
            max-width: 225px;
            font-size: 17px;
            line-height: 21px;
            text-align: center;
            color: $gray;
        }
    }
    &__object {
        position: relative;
        max-width: 380px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        margin-bottom: 70px;
        @include media(768) {
            margin-bottom: 15px;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 3px 0 0 3px;
            background: rgba(151, 213, 200, 0.15);
            border: 2px solid #97D5C8;
            border-right: 1px solid #97D5C8;
            width: 50%;
            height: 100%;
            @include media(768) {
                border-right: 2px solid #97D5C8;
                width: calc(50% - 2.5px);
            }
        }
        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 3px 3px 0;
            background: rgba(152, 214, 236, 0.02);
            border: 2px solid #98D6EC;
            border-left: 1px solid #98D6EC;
            width: 50%;
            height: 100%;
            @include media(768) {
                border-left: 2px solid #97D5C8;
                width: calc(50% - 2.5px);
            }
        }
        span {
            position: absolute;
            max-width: 50px;
            text-align: right;
            right: 10px;
            bottom: 10px;
            font-size: 13px;
            line-height: 16px;
            color: rgba(126, 160, 173, 0.7);
        }
    }
    &__info {
        margin-bottom: 65px;
        &_body {
            position: relative;
            display: flex;
            align-items: center;
            padding: 30px 0 40px;
            justify-content: space-between;
            @include media(768) {
                flex-direction: column-reverse;
                padding: 0;
            }
            &:after {
                content: '';
                width: 1000%;
                height: 100%;
                z-index: -1;
                background-color: $gray;
                border-radius: 5px;
                position: absolute;
                right: 150px;
                top: 0;
                @include media(768) {
                    right: -15px;
                    top: 60px;
                    border-radius: 0;
                    height: calc(100% - 40px);
                }
            }
            p {
                margin-right: 100px;
                color: $white;
                font-size: 17px;
                line-height: 21px;
                @include media(768) {
                    margin-right: 0;
                    text-align: center;
                }
                @include media(414) {
                    font-size: 14px;
                    line-height: 19px;
                }
            }
        }
        &_top {
            display: inline-flex;
            position: relative;
            padding: 12px 0 12px 40px;
            margin-bottom: 35px;
            @include media(768) {
                margin-bottom: 20px;
                padding: 10px 0;
            }
            &:after {
                content: '';
                width: 1000%;
                height: 100%;
                border: 2px solid $gray;
                border-radius: 5px;
                position: absolute;
                right: -70%;
                top: 0;
                @include media(768) {
                    width: calc(100% + 35px);
                    left: -20px;
                }
            }
            h3 {
                font-size: 18px;
                line-height: 22px;
                color: $gray;
                @include media(768) {
                    font-size: 16px;
                    line-height: 19px;
                }
            }
        }
        &_img {
            flex: 0 0 auto;
            max-width: 300px;
            border-radius: 10px;
            overflow: hidden;
            border: 1px solid $gray;
            @include media(768) {
                margin-bottom: 15px;
            }
            img {
                max-width: 100%;
            }
        }
        &_item {
            &:not(:last-child) {
                margin-bottom: 35px;
            }
        }
    }
    &__view {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        @include media(768) {
            flex-wrap: wrap;
        }
        @include media(414) {
            padding: 0;
            justify-content: center;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            border-radius: 5px;
            background-color: $gray;
            height: 180px;
            width: 100%;
            @include media(768) {
                width: 1000%;
            }
            @include media(414) {
                display: none;
            }
        }
        &_col {
            position: relative;
            text-align: center;
            h2 {
                font-size: 22px;
                line-height: 27px;
                color: $white;
                margin-bottom: 30px;
                @include media(768) {
                    color: $blue;
                }
            }
            button {
                @include media(768) {
                    display: none;
                }
            }
            &:nth-child(2) {
                @include media(768) {
                    order: -1;
                    width: 100%;
                }
            }
            &:first-child {
                @include media(414) {
                    margin-bottom: 30px;
                }
            }
            &:after {
                content: '';
                display: none;
                position: absolute;
                z-index: -1;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
                background-color: $gray;
                height: 180px;
                width: 1000%;
            }
            &:first-child,
            &:nth-child(3) {
                &:after {
                    @include media(414) {
                        display: block;
                    }
                }
            }
        }
        &_item {
            flex: 0 0 auto;
            max-width: 275px;
            overflow: hidden;
            border-radius: 5px;
            border: 2px solid $gray;
            height: 300px;
            img {
                max-width: 100%;
            }
        }
        & > button {
            display: none;
            margin-top: 30px;
            @include media(768) {
                display: flex;
            }
        }
    }
    &__option {
        display: none;
        justify-content: space-between;
        margin-bottom: 35px;
        &_item {
            position: relative;
            text-align: center;
            flex: 0 0 calc(50% - 25px);
            font-size: 16px;
            line-height: 19px;
            @include media(370) {
                flex: 0 0 calc(50% - 10px);
            }
            ul {
                li {
                    &:before {
                        @include size(10px);
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
            &:first-child {
                ul {
                    li {
                        text-align: right;
                        padding: 0 17px 0 0;
                        &:before {
                            right: 0;
                            left: auto;
                        }
                    }
                }
            }
            &:last-child {
                ul {
                    li {
                        text-align: left;
                        padding-left: 17px;
                    }
                }
            }
            span {
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 16px;
                @include media(768) {
                    font-size: 9px;
                    margin-bottom: 10px;
                }
            }
        }
        @include media(768) {
            display: flex;
        }
        @include media(360) {
            margin-bottom: 25px;
        }
    }
}