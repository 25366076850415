.s-adaptation {
    padding: 30px 0 40px;
    @include media(414) {
        padding: 20px 0 30px;
    }
}

.adaptation {
    &__title {
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 29px;
        @include media(768) {
            margin-bottom: 20px;
            font-size: 22px;
            line-height: 28px;
        
        }
        @include media(414) {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 24px;
        
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        margin-bottom: 25px;
        @include media(768) {
            flex-wrap: wrap;
        }
    }
    &__img {
        border: 3px solid $orange;
        border-radius: 10px;
        overflow: hidden;
        flex: 0 0 calc(50% - 20px);
        img {
            object-fit: cover;
            height: 100%;
            &:after {
                content: 'Картинка сломалась';
                text-align: center;
            }
            @include media(768) {
                width: 100%;
            }
        }
        &:nth-last-child(-n+2) {
            @include media(768) {
                margin-bottom: 0;
            }
        }
        @include media(768) {
            max-width: calc(50% - 15px);
            width: calc(50% - 15px);
            margin: 0 30px 30px 0;
        }
        @include media(414) {
            max-width: calc(50% - 5px);
            width: calc(50% - 5px);
            margin: 0 10px 10px 0;
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    &_cust {
        height: 220px;
        flex: 0 0 calc(25% - 15px);
        max-width: calc(25% - 15px);
        @include media(768) {
            flex: 0 0 calc(50% - 15px);
            max-width: calc(50% - 15px);
        }
        img {
            width: 100%;
        }
    }
    &__col {
        position: relative;
        z-index: 1;
        &:after {
            content: '';
            display: none;
            position: absolute;
            z-index: -1;
            right: 0;
            width: 1000%;
            height: 150px;
            background-color: $gray;
            top: 50%;
            transform: translateY(-50%);
        }
        &:not(:last-child) {
            margin-right: 25px;
            @include media(768) {
                width: 100%;
                order: 1;
                margin-right: 0;
            }
            .adaptation__img {
                @include media(768) {
                    margin: auto;
                }
            }
            &:after {
                @include media(768) {
                    display: block;
                    right: -15px;
                }
                @include media(414) {
                    height: 100px;
                }
            }
        }
        &:last-child {
            @include media(768) {
                margin-bottom: 15px;
            }
        }

        &_list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 30px 0 15px;
            @include media(768) {
                margin: 15px 0;
                justify-content: center;
            }
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 15px;
        @include media(768) {
            justify-content: center;
        }
        @include media(414) {
            display: none;
        }
    }
    &__slider {
        display: none;
        margin-bottom: 20px;
        @include media(414) {
            display: block;
        }
    }
    p {
        font-size: 16px;
        line-height: 20px;
        color: $gray;
        @include media(414) {
            font-size: 14px;
            line-height: 21px;
        }
    }
    .Button {
        margin-top: 50px;
        padding: 11px 50px;
        @include media(768) {
            margin-top: 20px;
        }
        @include media(414) {
            padding: 11px 20px;
        }
    }
}