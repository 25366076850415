.s-gallery {
    position: relative;
    padding: 30px 0 0;
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: 58%;
        transform: translateY(-50%);
        height: 250px;
        width: 100%;
        background-color: $gray;
        @include media(1440) {
            height: 240px;
        }
        @include media(768) {
            display: none;
        }
    }
    &_max {
        padding: 30px 0 50px;
        @include media(768) {
            padding: 60px 0 50px;
        }
        &:after {
            top: 52.7%;
        }
    }
    &_seven {
        @include media(768) {
            padding: 60px 0 0;
        }
        .gallery__board {
            @include media(414) {
                margin-bottom: 0;
            }
        }
    }
    .lineGroup {
        display: none;
        @include media(768) {
            display: block;
        }
        &__item {
            height: 24px;
        }
    }
}

.gallery {
    position: relative;
    height: 470px;
    @include media(1440) {
        height: 430px;
    }
    @include media(768) {
        height: auto;
        max-width: 805px;
        margin-right: -30px;
    }
    @include media(414) {
        margin-left: -15px;
    }
    &__inner {
        @include media(768) {
            overflow: auto;
        }
    }
    &__title {
        margin-bottom: 20px;
    }
    &__tabs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        @include media(768) {
            min-width: 835px;
            justify-content: flex-start;
        }
        &_item {
            padding: 10px 20px;
            background-color: $gray;
            border-radius: 12px;
            color: $white;
            font-size: 18px;
            transition: all .3s;
            cursor: pointer;
            margin: 0 5px;
            border: 2px solid $orange;
            &.active {
                background-color: $orange;
            }
            @include media(1440) {
                font-size: 16px;
            }
            @include media(768) {
                font-size: 13px;
                padding: 10px 15px;
            }
        }
    }
    &__board {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        max-width: 800px;
        height: 350px;
        background-color: $gray;
        border: 5px solid $orange;
        @include media(1440) {
            max-width: 700px;
            height: 300px;
        }
        @include media(768) {
            position: relative;
            top: auto;
            left: auto;
            transform: translate(0,0);
            min-width: 805px;
            margin: 15px auto;
            height: 232.5px;
            overflow: hidden;
        }
        &_items {
            width: 975px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            display: flex;
            flex-wrap: wrap;
            @include media(1440) {
                width: 825px;
            }
            @include media(768) {
                position: static;
                transform: translate(0,0);
            }
        }
        &_icon {
            position: absolute;
            pointer-events: none;
            opacity: 0;
            z-index: 1;
            transition: opacity .3s;
            fill: $white;
            @include size(50px);
        }
        &_item {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: calc(20% - 15px);
            max-width: 180px;
            height: 140px;
            cursor: pointer;
            margin: 7.5px;
            border-radius: 5px;
            border: 2px solid $orange;
            &:after {
                content: '';
                position: absolute;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                top: -2px;
                border-radius: 5px;
                right: 0;
                bottom: 0;
                left: -2px;
                transition: opacity .3s;
                pointer-events: none;
                opacity: 0;
                background: rgba(133, 168, 182, .6);
            }
            &:hover {
                &:after {
                    opacity: 1;
                }
                .gallery__board_icon {
                    opacity: .6;
                }
            }
            @include media(1440) {
                max-width: 150px;
                height: 100px;
            }
            @include media(768) {
                margin: 7.5px 0 0 7.5px;
            }
            a {
                width: 100%;
                height: 100%;
                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 3px;
                    object-fit: cover;
                }
            }
        }
    }
    &_max {
        display: flex;
        align-items: center;
        .gallery__tabs {
            width: 100%;
            max-width: 1000px;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            @include media(768) {
                position: static;
                left: 0;
                transform: translateX(0);
                height: auto;
            }
            .gallery__tabs_item {
                &:nth-child(n + 5) {
                    margin-top: auto;
                }
                &:nth-child(-n + 4) {
                    margin-bottom: auto;
                    @include media(768) {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}