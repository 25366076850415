.s-glass {
    padding: 30px 0;
    background-color: #fff;
    &__after {
        position: absolute;
        right: 0;
        top: 10px;
        z-index: 999;
        @include media(414) {
            max-width: 40px;
        }
        @include media(320) {
            max-width: 25px;
        }
    }
    @include media(414) {
        padding: 15px 0 10px 0;
    }
}

.glass {
    position: relative;
    z-index: 1;
    &__col {
        display: flex;
        position: relative;
        width: 40%;
        justify-content: space-around;
        @include media(768) {
            width: 100%;
        }
        @include media(370) {
            justify-content: space-between;
        }
        .Button {
            display: none;
            padding: 12px 18px;
            font-size: 15px;
            @include media(768) {
                display: flex;
                margin: 32px 0 0;
                height: 48px;
            }
            @include media(414) {
                padding: 12px;
                margin-left: -30px;
                font-size: 14px;
            }
            @include media(370) {
                margin-left: 0
            }
        }
        &:not(:last-child) {
            @include media(768) {
                margin-bottom: 20px;
            }
        }
        &:last-child {
            width: 20%;
            @include media(768) {
                width: 100%;
            }
        }
        &:after {
            content: '';
            position: absolute;
            top: 22px;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 70px;
            background-color: $blue;
            @include media(768) {
                width: calc(100% + 110px);
                left: -55px;
            }
        }
    }
    
    &__list {
        display: flex;
        justify-content: space-between;
        padding: 0 40px;
        @include media(414) {
            padding: 0;
        }
        @include media(768) {
            flex-wrap: wrap;
        }
    }
    &__item {
        position: relative;
        padding: 0 15px;
        cursor: pointer;
        @include media(414) {
            max-width: 120px;
            padding: 0;
        }
        .glass__info {
            @include media(700) {
                display: none;
            }
        }
        img {
            @include media(414) {
                max-width: 120px;
            }
        }
        span {
            display: block;
            width: 125px;
            font-weight: 400;
            text-align: center;
            margin-top: 12px;
            font-size: 15px;
            line-height: 18px;
            color: $blue;
            @include media(414) {
                width: 110px;
                margin-top: 10px;
            }
        }
        &:hover {
            .glass__info {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                bottom: 118%;
            }
        }
    }
    &__info {
        width: 400px;
        position: absolute;
        pointer-events: none;
        transition: all .3s linear;
        opacity: 0;
        visibility: hidden;
        left: 0;
        bottom: 115%;
        background: #FFFFFF;
        border: 4px solid #D4E2E8;
        border-radius: 10px;
        padding: 16px;
        p {
            font-size: 14px;
            line-height: 20px;
            font-weight: 600;
            user-select: none;
            color: $blue;
        }
        &:after {
            content: '';
            position: absolute;
            left: 10px;
            bottom: -24px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 20px 15px 0 15px;
            border-color: #d4e2e8 transparent transparent transparent;
        }
        &:before {
            content: '';
            position: absolute;
            bottom: -30px;
            left: 0;
            height: 30px;
            width: 140px;
        }
        &_after {
            position: absolute;
            z-index: -1;
            top: 50%;
            right: -50px;
            transform: translateY(-50%);
            background: rgba(187, 227, 241, 0.2);
            border: 1px solid rgba(184, 227, 242, 0.7);
            height: calc(100% + 40px);
            width: 80%;
            @include media(768) {
                display: none;
            }
        }
    }
}
