.fixedMenu {
    position: fixed;
    z-index: 9999;
    width: 100%;
    left: 0;
    bottom: 0;
    &__icon {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        fill: $gray;
        @include size(20px);
        &.left {
            left: 10px;
            @include media(414) {
                left: 20px;
            }
        }
        &.right {
            right: 10px;
            @include media(414) {
                right: 20px;
            }
        }
        @include media(768) {
            @include size(15px);
        }
        @include media(414) {
            top: 17px;
            @include size(18px);
        }
    }
    &__list {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }
    &__left {
        clip-path: polygon(0 0, 75% 0, 100% 100%, 0% 100%);
        &:after {
            clip-path: polygon(0% 0%, 75% 1%, 99% 100%, 0% 100%); 
        }
       
    }
    &__hide {
        flex: 0 0 220px;
        @include media(768) {
            flex: 0 0 170px;
        }
        @include media(414) {
            flex: 0 0 60px;
        }
    }
    &__right {
        clip-path: polygon(25% 0, 100% 0, 100% 100%, 0% 100%);
        &:after {
            clip-path: polygon(25% 1%, 100% 0, 100% 100%, 1% 100%);
        }
    }
    &__right,
    &__left {
        position: relative;
        flex: 0 0 200px;
        opacity: 0.8;
        text-align: center;
        line-height: 50px;
        height: 50px;
        background: rgba(152, 214, 236, .7);
        @include media(768) {
            height: 40px;
            line-height: 40px;
            flex: 0 0 170px;
        }
        @include media(414) {
            height: 35px;
            line-height: 35px;
            flex: 0 0 70px;
        }
        span {
            color: $gray;
            font-size: 14px;
            transition: color .3s;
            font-weight: 400;
            @include media(414) {
                display: none;
            }
        }
        &:hover {
            span {
                color: #657f89;
            }
        }
        &:after {
            content: '';
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: #eef6f9;
        }
    }
    &__romb {
        position: relative;
        left: 0;
        bottom: 0;
        flex: 0 0 200px;
        height: 40px;
        clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
        background: rgba(152, 214, 236, .7);
        &:after {
            content: '';
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            flex: 0 0 154px;
            clip-path: polygon(20% 0%, 80% 0%, 99% 100%, 1% 100%);
            transform: translate(-50%, -50%);
            background: #eef6f9;
        }
        @include media(768) {
            height: 30px;
        }
    }
    &__link {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        flex: 0 0 200px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
            position: relative;
            @include size(10px);
            border-radius: 50%;
            background-color: $gray;
            &:not(:last-child) {
                margin-right: 8px;
            }
            &.active {
                background-color: $orange;
            }
            @include media(768) {
                @include size(8px);
            }

        }
        @include media(768) {
            height: 30px;
        }
    }
    &__center {
        position: relative;
        display: flex;
        opacity: 0.8;
        align-items: center;
        justify-content: center;
        flex: 0 0 200px;
        height: 40px;
        @include media(768) {
            height: 30px;
        }
        @include media(414) {
           display: none;
        }
    }
}