.sandwich {
    cursor: pointer;
    height: 20px;
    position: relative;
    margin-left: 20px;
    width: 32px;
    &--active {
      .sandwich__line {
        &--top {
          top: 10px;
          transform: rotate(45deg);
        }
        &--middle { opacity: 0; }
        &--bottom {
          top: 10px;
          transform: rotate(-45deg);
        }
      }
    }
    &__line {
      background-color: #7EA0AD;
      display: block;
      height: 2px;
      left: 0;
      position: absolute;
      transition: all linear .4s;
      width: 100%;
      &--top { top: 0; }
      &--middle { top: 9px; }
      &--bottom { top: 18px; }
    }
  }