.s-advantages {
    padding: 30px 0 50px;
    background-color: $blue;
    @include media(414) {
        padding: 15px 0 30px;
    }
    &__before,
    &__after {
        position: absolute;
    }
    &__before {
        left: 0;
        top: 0;
        @include media(768) {
            max-width: 120px;
        }
        @include media(414) {
            max-width: 80px;
        }
        @include media(320) {
            max-width: 50px;
        }
    }
    &__after {
        right: 0;
        bottom: 0;
        @include media(414) {
            max-width: 40px;
        }
        @include media(320) {
            max-width: 25px;
        }
    }
    h2 {
        & ~ p {
            max-width: 1050px;
            font-size: 17px;
            line-height: 21px;
            margin: 0 auto 8px;
            color: $white;
            user-select: none;
            font-weight: 400;
            text-align: center;
            @include media(414) {
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
    &_six {
        padding: 80px 0;
        .lineGroup {
            &__item {
                height: 24px;
            }
        }
    }
    &_two {
        padding: 120px 0;
        @include media(414) {
            padding: 70px 0;
        }
        &__title {
            margin-bottom: 17px;
        }
    }
    &_seven {
        padding: 80px 0 100px;
        @include media(768) {
            padding: 40px 0 70px;
        }
        .lineGroup {
            &_top {
                @include media(768) {
                    display: none;
                }
            }
            &__item {
                height: 24px;
            }
        }
    }
    &_four {
        padding: 30px 0 70px;
        .lineGroup {
            display: none;
            @include media(768) {
                display: block;
            }
            &__item {
                height: 24px;
            }
        }
    }
    .Button-270 {
        @include media(414) {
            width: auto;
        }
    }
}

.psevdo {
    display: none;
    position: absolute;
    @include media(414) {
        display: block;
    }
}
.before-left {
    top: 0;
    left: 0;
}
.before-right {
    top: 0;
    right: 0;
}

.after-left {
    bottom: 50px;
    left: 0;
}
.after-right {
    bottom: 50px;
    right: 0;
}

.advantages {
    position: relative;
    z-index: 1;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    @include media(414) {
        padding: 0 35px;
    }
    @include media(320) {
        padding: 0;
    }
    &_one {
        padding: 45px 0 55px;
    }
    &__title {
        margin-bottom: 60px;
    }
    &__item {
        position: relative;
        width: calc(33.33% - 50px);
        max-width: calc(33.33% - 50px);
        margin-right: 75px;
        padding: 45px 20px 15px;
        text-align: center;
        letter-spacing: 0.04em;
        background-color: $blue;
        color: $white;
        margin-bottom: 80px;
        border: 3px solid transparent;
        @include media(768) {
            width: calc(45% - 25px);
            max-width: calc(45% - 25px);
            margin-right: 50px;
        }
        @include media(414) {
            width: 100%;
            max-width: 100%;
            margin-right: 0;
            margin-bottom: 60px;
        }
        &:nth-child(2n) {
            @include media(768) {
                margin-right: 0;
            }
        }
        &:nth-child(2) {
            .advantages__item-img {
                &:after,
                &:before {
                    display: block;
                }
            }
        }
        &-img {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: 50%;
            top: -35.5px;
            background-color: #7EA0AD;
            transform: translateX(-50%);
            @include size(68px);
            border: 3px solid #B4AC85;
            border-radius: 50%;
            &:after {
                content: '';
                position: absolute;
                display: none;
                right: -88px;
                top: -14px;
                background: url('../images/img/adv-right.png');
                width: 95px;
                height: 75px;
            }
            &:before {
                content: '';
                position: absolute;
                display: none;
                left: -103px;
                top: -22px;
                background: url('../images/img/adv-left.png');
                width: 105px;
                height: 80px;
            }
        }
        h3 {
            margin-bottom: 5px;
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
        }
        p {
            line-height: 17px;
            font-size: 14px;
            font-weight: 400;
            user-select: none;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -6px;
            right: -6px;
            top: -6px;
            bottom: -6px;
            background: rgb(244,185,85);
            background: linear-gradient(150deg, #f4b955 0%, #91b7c6 75%);
        }
        &:nth-child(3n) {
            margin-right: 0;
            @include media(768) {
                margin-right: 50px;
            }
            @include media(414) {
                margin-right: 0;
            }
        }
        &:last-child {
            @include media(768) {
                margin-right: 0;
            }
            @include media(414) {
                margin-bottom: 40px;
            }
        }
    }
    &_two {
        margin-top: 70px;
    }
    &_seven {
        .advantages__item {
            margin-bottom: 50px;
        }
        &__title {
            margin-bottom: 60px;
        }
    }
}

.lineGroup {
    width: 100%;
    position: absolute;
    z-index: 100;
    left: 0;
    &__item {
        height: 24px;
        background: linear-gradient(90deg, #598596 -0.47%, #90B6C5 49.77%, #7EA0AD 100%);
        border-top: 1px solid #C4DAE2;
        border-bottom: 1px solid #C4DAE2;
        @include media(414) {
            height: 14px;
        }

    }
    &_top {
        top: 0;
    }
    &_bottom {
        bottom: 0;
    }
}

.adv-before,
.adv-after {
    position: absolute;
    @include media(768) {
        display: none;
    }
}

.adv-m-before {
    display: none;
    position: absolute;
    left: 0;
    top: 50px;
    @include media(768) {
        display: block;
    }
    @include media(414) {
        max-width: 70px;
    }
}

.adv-before {
    left: 0;
    top: 50px;
}
.adv-after {
    right: 0;
    bottom: 60px;
}