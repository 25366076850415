
.is-locked {
    overflow: hidden;
 }
 
 .popup {
    position: absolute;
    display: block;
    width: 100%;
    max-width: 400px;
    padding: 20px 50px;
    margin: 30px 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.85);
    background-color: #fff;
    border: 4px solid #F4B955;
    border-radius: 10px;
    transition: all .3s linear;
    opacity: 0;
    visibility: hidden;
    z-index: 9999999;
    @include media(414) { padding: 20px; }
    &__star {
        position: absolute;
        &._one {
            top: 28px;
            left: 22px;
        }
        &._two {
            right: 20px;
            top: 17px;
        }
        &._three {
            right: 110px;
            bottom: 53px;
        }
    }
    &__time,
    &__date {
        width: 44px;
        height: 29px;
        border: 1px solid #F4B955;
        border-radius: 5px;
        input {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            padding: 0;
            color: $blue;
            text-align: center;
            &::placeholder {
                font-size: 16px;
                line-height: 20px;
                color: #D8E2E6;
            }
        }
    }
    &__date {
        position: relative;
        flex: 1;
        margin-left: 13px;
        padding: 6px 20px 6px 16px;
        &_icon {
            position: absolute;
            pointer-events: none;
            right: 16px;
            top: 50%;
            transform: translateY(-50%);
            @include size(18px);
            fill: #F4B955;
        }
        input {
            text-align: left;
            &::placeholder {
                font-size: 12px;
            }
        }
    }
    &__timeDate {
        margin-bottom: 22px;
        input {
            &::placeholder {
                transition: all .3s linear;
            }
            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
        &_list {
            display: flex;
            align-items: center;
            span {
                margin: 0 6px;
            }
        }
        & > span {
            display: block;
            font-size: 15px;
            line-height: 18px;
            color: $blue;
            text-align: center;
            margin-bottom: 20px;
        }
    }
    &__textarea {
        margin-bottom: 30px;
        textarea {
            width: 100%;
            resize: vertical;
            border: 1px solid #F4B955;
            padding: 13px 15px 0 15px;
            border-radius: 5px;
            outline: none;
            &::placeholder {
                font-size: 16px;
                line-height: 20px;
                color: #D8E2E6;
            }
        }
    }
    &__select {
        width: calc(100% - 40px);
        margin: 17px auto 10px;
        &_item {
            position: relative;
            cursor: pointer;
            border: 1px solid #F4B955;
            padding: 7px 50px 7px 12px;
            border-radius: 5px;
            &.active {
                &:after {
                    border-width: 0 15px 20px 15px;
                    border-color: transparent transparent #F4B955 transparent;
                }
            }
            span {
                font-size: 14px;
                line-height: 17px;
                font-weight: 400;
            }
            &:after {
                content: '';
                position: absolute;
                transition: all .3s linear;
                right: 12px;
                transform: translateY(-50%);
                pointer-events: none;
                top: 50%;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 20px 15px 0 15px;
                border-color: #F4B955 transparent transparent transparent;
            }
        }
        p {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #B0C3CA;
        }
    }
    
    &-form {
        font-size: 13px;
        line-height: 16px;
        color: $blue;
        p {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #B0C3CA;
            margin-top: 4px;
            font-weight: 400;
        }
        label {
            display: inline-block;
            margin-bottom: 3px;
        }
    }
    &__input {
        position: relative;
        margin-bottom: 10px;
        .invalid {
            top: 22px;
        }
        p {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            color: #B0C3CA;
        }
        input {
            width: 100%;
            height: 48px;
            font-size: 16px;
            border: 1px solid #F4B955;
            border-radius: 5px;
            padding: 14px;
            &::placeholder {
                color: #D8E2E6;
                transition: all .3s linear;

            }
            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
    }
    &__success,
    &__error {
        height: 400px;
        padding: 0;
    }
    &-success {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        color: $gray;
        font-weight: 600;
        text-align: center;
        h2,p {
            font-size: 20px;
            line-height: 24px;
        }
        h2 {
            margin-bottom: 20px;
        }
    }
}



 .popup._show {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
    visibility: visible;
    & ~ .popup-close {
        opacity: 1;
        z-index: 99999;
    }
 }
 .popup-close {
    display: flex;
    opacity: 0;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: fixed;
    z-index: -1;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #F4B955;
    right: 10px;
    top: 10px;
    cursor: pointer;
    &__icon {
        @include size(15px);
        fill: #85A8B6;
    }
 }


.popup-caption {
    font-size: 16px;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
    color: $gray;
    margin-bottom: 22px;
    @include media(414) {
        margin-bottom: 20px;
        font-size: 16px;
    }
 }
 
 .popup-overlay {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(182, 199, 206, 0.25);
    backdrop-filter: blur(50px);
    z-index: 99990;
 }
 

 .animated-modal {
    max-width: 550px;
    border-radius: 4px;
    overflow: hidden;
    transition: all .7s;
    p {
        @include media(414) {
            font-size: 14px;
            line-height: 18px;
        }
    }
  }
  
  .animated-modal h2,
  .animated-modal p {
    transform: translateY(-50px);
    opacity: 0;
    transition-property: transform, opacity;
    transition-duration: .4s;
    line-height: 24px;
    color: $blue;
  }
  .animated-modal h2 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 10px;
    @include media(414) {
        font-size: 18px;
    }
  }

  .popup-glass {
    padding: 20px 30px;
    @include media(414) {
        padding: 20px 10px 10px;
    }
    .fancybox-button {
        padding: 6px;
    }
    .fancybox-button svg {
        color: $blue;
    }
  }
  .popup-question {
    @include media(414) {
        padding: 40px 10px 10px;
    }
  }
  
  /* Final state */
  .fancybox-slide--current .animated-modal h2,
  .fancybox-slide--current .animated-modal p {
    transform: translateY(0);
    opacity: 1;
  }
  
  /* Reveal content with different delays */
  .fancybox-slide--current .animated-modal h2 {
    transition-delay: .1s;
  }
  
  .fancybox-slide--current .animated-modal p {
    transition-delay: .3s;
  }
 