.s-panno {
    padding: 50px 0 40px;
    @include media(414) {
        padding: 20px 0 10px;
    }
}

.panno {
    &__title {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 20px;
        @include media(414) {
            font-size: 18px;
            line-height: 22px;
            margin-bottom: 12px;
        }
    }
    p {
        max-width: 1060px;
        margin: auto;
        font-size: 17px;
        line-height: 21px;
        text-align: center;
        user-select: none;
        color: $gray;
        @include media(414) {
            font-size: 14px;
            line-height: 19px;
            text-align: left;
        }
    }
}

.p-gallery {
    position: relative;
    width: 100%;
    margin: 80px 0;
    @include media(768) {
        margin: 30px 0;
    }
    @include media(414) {
        margin: 20px 0;
    }
    &__item {
        overflow: hidden;
        border-radius: 10px;
        border: 1px solid $gray;
        @include media(768) {
            width: calc(50% - 15px);
            max-width: calc(50% - 15px);
            margin: 0 30px 30px 0;
        }
        @include media(414) {
            width: calc(50% - 5px);
            max-width: calc(50% - 5px);
            margin: 0 10px 10px 0;
        }
        &:nth-child(2n) {
            @include media(768) {
                margin-right: 0;
            }
        }
        img {
            @include media(768) {
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__list {
        position: relative;
        display: flex;
        justify-content: space-between;
        height: 560px;
        @include media(768) {
            flex-direction: column;
            height: auto;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 100%;
            max-width: 800px;
            height: 400px;
            background-color: $gray;
            @include media(768) {
                display: none;
            }
        }
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 110%;
            height: 500px;
            border: 3px solid $orange;
            @include media(768) {
                transform: translateX(-50%);
                top: 50px;
                width: 90%;
                height: calc(100% - 190px);
            }
            @include media(414) {
                top: 25px;
            }
            @include media(320) {
                height: calc(100% - 220px);
            }
        }
    }
    &__col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        @include media(768) {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
        }
        &:nth-child(2) {
            margin: -50px 0;
            @include media(768) {
                margin: 0;
                order: 1;
            }
            .p-gallery__item {
                &:last-child {
                    @include media(768) {
                        margin-right: 0;
                    }
                }
                &:last-child,
                &:first-child {
                    @include media(768) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
        p {
            max-width: 375px;
            font-size: 17px;
            line-height: 21px;
            color: $white;
            text-align: center;
            @include media(768) {
                color: $blue;
                width: 100%;
                order: 1;
                max-width: 100%;
            }
            @include media(414) {
                font-size: 14px;
                line-height: 21px;
            }
        }
    }
    & + p {
        @include media(414) {
            padding: 10px 15px;
            background-color: $blue;
            color: $white;
            margin-left: -15px;
            margin-right: -15px;
        }
    }
    
}

.simile {
    position: relative;
    border-top: 1px solid $orange;
    margin: 50px 0 0;
    padding: 50px 0 0;
    @include media(414) {
        margin: 20px 0 0;
        padding: 10px 0 0;
        border: none;
    }
    &__text {
        position: absolute;
        top: -16px;
        background-color: $white;
        max-width: 405px;
        padding: 5px 10px;
        color: $gray;
        font-size: 17px;
        line-height: 21px;
        @include media(414) {
            position: static;
            font-size: 14px;
            text-align: center;
            line-height: 19px;
            margin-bottom: 15px;
        }
    }
    p {
        max-width: 1060px;
        margin: 0 auto 30px;
        font-size: 17px;
        user-select: none;
        line-height: 21px;
        text-align: center;
        color: $gray;
        @include media(414) {
            margin: 0 auto 20px;
            font-size: 14px;
            line-height: 19px;
        }
    }
    &__list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 30px;
        @include media(768) {
            justify-content: center;
            margin-bottom: 20px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            width: 1000%;
            left: -70px;
            background-color: $gray3;
            top: 50%;
            transform: translateY(-50%);
            height: 130px;
            border-radius: 5px 0px 0px 5px;
            @include media(768) {
                height: 80%;
                left: 0;
            }
            @include media(414) {
                left: -15px;
                border-radius: 0;
            }
        }
        & + span {
            display: inline-block;
            max-width: 535px;
            font-size: 17px;
            line-height: 21px;
            margin-bottom: 10px;
            color: $gray;
            @include media(768) {
                text-align: center;
                max-width: 100%;
            }
            @include media(414) {
                font-size: 14px;
                line-height: 19px;
            }
        }
    }
    &_after {
        &:after {
            left: auto;
            right: -70px;
            background-color: $orange;
            border-radius: 0px 5px 5px 0px;
        }
    }
    &__item {
        overflow: hidden;
        border-radius: 10px;
        border: 3px solid $gray;
        @include media(768) {
            width: calc(50% - 15px);
            max-width: calc(50% - 15px);
            height: 250px;
            margin: 0 30px 30px 0;
        }
        @include media(414) {
            width: 100%;
            max-width: 100%;
            height: 250px;
            margin: 0 0 20px 0;
        }
        img {
            @include media(768) {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:nth-child(2) {
            @include media(768) {
                margin-right: 0;
            }
        }
        &:last-child {
            @include media(768) {
                margin: 0;
            }
        }
    }
    &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        & > * {
            width: 100%;
            max-width: 360px;
            margin: 0;
            &:not(:last-child) {
                margin-right: 25px;
                @include media(414) {
                    display: none;
                }
            }
            &:last-child {
                img {
                    display: none;
                }
                @include media(414) {
                    img {
                        display: block;
                    }
                }
            }
            @include media(414) {
                width: auto;
            }
        }
    }
}