.swiper-container {
    width: 100%;
    max-width: 700px;
    height: 360px;
    margin: auto;
    z-index: 2;
    @include media(768) {
        max-width: 600px;
        height: 300px;
    }
    @include media(414) {
        height: auto;
    }
}

.swiper {
    &-next,
    &-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        cursor: pointer;
        height: 36px;
        border-radius: 5px;
        transition: all .3s linear;
        border: 1px solid #F4B955;
        background-color: $blue;
        &:hover {
            border-color: #f4cb37;
        }
    }
    &-arrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 80px);
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &_icon {
            @include size(22px);
            fill: $white;
        }
        @include media(768) {
            display: none;
        }
    }
    &-gallery {
        .swiper-slide {
            width: 240px;
            padding: 3px;
            background: linear-gradient(180deg, #e7d7b8 0%, #8badba 45%, #91b7c6 100%);
            height: 200px;
            border: 0px solid transparent;
            img {
                border-radius: 6px;
            }
            @include media(375) {
                width: 200px;
                height: 180px;
            }
        }
    }
}

.swiper-slide img {
    position: relative;
    display: block;
    margin: auto;
    object-fit: cover;
    height: 100%;
    width: 100%;
    &:before {  
        content: " ";
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgb(230, 230, 230);
        border-radius: 5px;
    }
    &:after {  
        content: "\1F937" " " attr(alt);
        display: block;
        font-size: 16px;
        color: rgb(100, 100, 100);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
    }
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #73a7bb;
    user-select: none;
    width: 300px;
    cursor: pointer;
    height: 340px;
    padding: 0;
    border: 1px solid #F4B955;
    box-sizing: border-box;
    border-radius: 10px;
    overflow: hidden;
    @include media(768) {
        width: 250px;
        height: 300px;
    }
    @include media(414) {
        width: 200px;
        height: 220px;
    }
}



.slickSlider {
    max-width: 704px;
    margin: auto;
    &__item {
        display: flex!important;
        align-items: center;
        justify-content: center;
        width: 170px;
        height: 125px;
        overflow: hidden;
        margin-right: 8px;
        background-size: contain;
        background-color: $white;
        outline: none;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px;
        border: 1px solid $blue;
        img {
            max-width: 100px;
            @include media(414) {
                max-width: 80px;
            }
        }
        @include media(414) {
            width: 140px;
            height: 100px;
        }
    }
    &__arrows {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        width: calc(100% + 50px);
        transform: translate(-50%,-50%);
        display: flex;
        justify-content: space-between;
        @include media(768) {
            display: none;
        }
    }
    &__arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 52px;
        height: 36px;
        cursor: pointer;
        border-radius: 5px;
        background-color: $blue;
        border: 1.5px solid #F4B955;
        &_icon {
            @include size(25px);
            fill: $white;
        }
    }
}

.slider-image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    text-align: center;
    width: 100%;
    height: 100%;
    &:after {
        content: '';
        position: absolute;
        width: calc(100% + 4px);
        height: calc(100% + 4px);
        top: -2px;
        border-radius: 5px;
        right: 0;
        bottom: 0;
        left: -2px;
        transition: opacity .3s;
        pointer-events: none;
        opacity: 0;
        background: rgba(255,255,255,.9);
    }
    a {
        display: block;
        height: 100%;
        width: 100%;
    }
}

.preloader-swiper {
    overflow: hidden;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 50%;
        z-index: 1;
        width: 500%;
        margin-left: -250%;
        animation: phAnimation $ph-animation-duration linear infinite;
        background: linear-gradient(to right, rgba($ph-bg, 0) 46%, rgba($ph-bg, .35) 50%, rgba($ph-bg, 0) 54%) 50% 50%;
      }
}

@keyframes phAnimation {
    0% {
      transform: translate3d(-30%, 0, 0);
    }
    100% {
      transform: translate3d(30%, 0, 0);
    }
  }