.header {
    position: relative;
    width: 100%;
    height: 637px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background: url('../images/img/dots.png');
    @include media(768) {
        height: 470px;
    }
    @include media(414) {
        height: auto;
    }
    &.header_one {
        height: 730px;
        &:after {
            z-index: -1;
            height: 125px;
            @include media(768) {
                height: 95px;
            }
            @include media(414) {
                height: 0;
            }
        }
        &:before {
            display: block;
            @include media(768) {
                display: none;
            }
        }
        .header__before,
        .header__after {
            z-index: 1;
        }
        @include media(768) {
            height: 550px;
        }
        @include media(414) {
            height: auto;
        }

    }
    &__btn {
        &_list {
            display: flex;
            justify-content: center;
            margin-top: -30px;
            button {
                @include media(768) {
                    display: none;
                }
            }
            & > * {
                &:not(:last-child) {
                    margin-right: 50px;
                    @include media(768) {
                        margin-right: 0;
                    }
                }
            }
            @include media(768) {
                margin-top: -20px;
            }
            @include media(414) {
                margin-top: -25px;
                padding: 20px 0;
                background-color: $blue;
            }
            a {
                position: relative;
                img {
                    position: absolute;
                    left: -35px;
                    max-width: 28px;
                    display: none;
                    @include media(768) {
                        display: block;
                    }
                }
            }
        }
    }
    &__text {
        position: absolute;
        width: 100%;
        max-width: 420px;
        left: 50%;
        text-transform: uppercase;
        color: $gray2;
        top: 40%;
        transform: translate(-50%,-50%);
        @include media(768) {
            max-width: 330px;
        }
        @include media(414) {
            max-width: 250px;
        }
        @include media(360) {
            max-width: 190px;
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            li {
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                &:not(:last-child) {
                    margin-right: 40px;
                    @include media(414) {
                        margin-right: 12px;
                    }
                    @include media(360) {
                        margin-right: 8px;
                    }
                }
                @include media(414) {
                    font-size: 12px;
                    line-height: 16px;
                }
                @include media(360) {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }
        &_page {
            h1 {
                margin-bottom: 16px;
                font-size: 39px;
                line-height: 48px;
                user-select: none;
                @include media(414) {
                    margin-bottom: 10px;
                }
            }
        }
        h1 {
            font-weight: 500;
            font-size: 30px;
            line-height: 37px;
            text-align: center;
            user-select: none;
            @include media(768) {
                font-size: 22px;
                line-height: 28px;
            }
            @include media(414) {
                font-size: 18px;
                line-height: 24px;
            }
            @include media(360) {
                font-size: 14px;
                line-height: 18px;
            }
            span {
                text-transform: uppercase;
                user-select: none;
                &:first-child {
                    @include media(768) {
                        display: none;
                    }
                }
                &:last-child {
                    display: none;
                    @include media(768) {
                        display: block;
                    }
                }
                
            }
        }
        &_two {
            h1 {
                font-size: 26px;
                line-height: 32px;
                margin-bottom: 30px;
                @include media(414) {
                    font-size: 19px;
                    line-height: 26px;
                    margin-bottom: 0;
                }
                @include media(320) {
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            button {
                @include media(414) {
                    display: none;
                }
            }
        }
        &_four {
            h1 {
                margin-bottom: 50px;
                @include media(768) {
                    margin-bottom: 20px;
                }
                @include media(414) {
                    margin-bottom: 0;
                }
            }
            button {
                @include media(414) {
                    display: none;
                }
            }
        }
        &_five {
            h1 {
                margin-bottom: 40px;
                @include media(414) {
                    margin-bottom: 0;
                }
            }
            button {
                @include media(414) {
                    display: none;
                }
            }
        }
        &_six {
            max-width: 410px;
            @include media(768) {
                max-width: 300px;
            }
            @include media(414) {
                max-width: 245px;
            }
            h1 {
                margin-bottom: 50px;
                @include media(768) {
                    margin-bottom: 20px;
                }
                @include media(414) {
                    margin-bottom: 0;
                }
            }
            button {
                @include media(414) {
                    display: none;
                }
            }
        }
        &_seven {
            text-transform: none;
            h1 {
                margin-bottom: 35px;
                @include media(414) {
                    margin-bottom: 0;
                }
            }
            button {
                @include media(414) {
                    display: none;
                }
            }
        }
    }
    &__center {
        position: relative;
        max-width: 800px;
        margin: auto;
        bottom: -50px;
        @include media(768) {
            position: relative;
            max-width: 550px;
            margin: auto;
            bottom: -80px;
        }
        @include media(414) {
            margin: 50px auto 0;
            bottom: -20px;
        }
        img {
            width: 100%;
        }
    }
    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 100%;
        pointer-events: none;
        bottom: 0;
        left: 0;
    }
    &:before {
        display: none;
        height: 10px;
        background: rgb(145, 183, 198);
        border-top: 1px solid rgba(92, 179, 227, 0.5);
        border-bottom: 1px solid rgba(92, 179, 227, 0.5);
    }
    &:after {
        height: 30px;
        background-color: $blue;
        @include media(768) {
            height: 10px;
        }
    }
    &_two,
    &_six {
        height: auto;
        &:after {
            display: none;
        }
        .header__center {
            bottom: -60px;
            @include media(414) {
                bottom: -30px;
            }
        }
        @include media(768) {
            height: 440px;
        }
    }
    &_two {
        @include media(414) {
            height: auto;
        }
        .header__after {
            top: 20px;
        }
    }
    &_six {
        .header__after,
        .header__before {
            top: 75px;
        }
        @include media(414) {
            height: 365px;
        }
        @include media(400) {
            height: auto;
        }
    }
    &_four,
    &_five, 
    &_seven {
        .header__center {
            @include media(768) {
                bottom: -90px;
            }
            @include media(414) {
                bottom: -30px;
            }
        }
        &:after {
            @include media(768) {
                height: 0;
            }
            
        }
    }
    &_three {
        .header__after,
        .header__before {
            z-index: 0;
        }
    }
    &__before,
    &__after {
        position: absolute;
        top: 60px;
        z-index: -1;
        @include media(768) {
            display: none;
        }
    }
    &__before {
        left: 0;
    }
    &__after {
        right: 0;
    }
    &__inner {
        position: fixed;
        z-index: 9999;
        pointer-events: none;
        width: 100%;
        display: flex;
        justify-content: space-between;
        @include media(768) {
            background-color: #f4fbfd;
            border-bottom: 1px solid rgba(152, 214, 236, 0.7);
        }
    }
    &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: all;
        height: 68px;
        width: 100%;
        max-width: 83px;
        background: rgb(238,246,249);
        border-radius: 0 0 25px 0;
        border: 1px solid rgba(152, 214, 236, 0.7);
        @include media(768) {
            max-width: 70px;
            height: 56px;
            border-radius: 0;
            border: none;
            border-right: 1px solid rgba(152, 214, 236, 0.7);
        }
    }
    &__nav {
        display: flex;
        align-items: center;
        pointer-events: all;
        padding: 18px;
        background: rgb(238,246,249);
        border: 1px solid rgba(152, 214, 236, 0.7);
        border-radius: 0 0 0 25px;
        @include media(768) {
            padding: 9px 18px;
            border-radius: 0;
            border: none;
            background: #f4fbfd;
        }
        &_link {
            display: flex;
            flex-direction: column;
            text-align: right;
            align-items: flex-end;
        }
        span {
            color: $blue;
            font-size: 14px;
            line-height: 19px;
            cursor: pointer;
            transition: all .3s linear;
            border-bottom: 1px solid $orange;
            &:hover {
                color: $black;
            }
        }
        a {
            font-size: 14px;
            line-height: 17px;
            color: $blue;
        }
    }
}

.logo {
    img {
        max-width: 41px;
        @include media(768) {
            max-width: 37px;
        }
    }
}

.section {
    &__title {
        text-align: center;
        margin-bottom: 30px;
        color: $blue;
        font-size: 22px;
        line-height: 27px;
        user-select: none;
        @include media(414) {
            font-size: 18px;
            margin-bottom: 16px;
            line-height: 21px;
        }
        span {
            &:first-child {
                @include media(414) {
                    display: none;
                }
            }
            &:nth-child(2) {
                display: none;
                @include media(414) {
                    display: block;
                }
            }
        }
    }
    &__white {
        color: $white;
    }
    &_bg {
        @include media(414) {
            position: relative;
            color: $white;
            background-color: $blue;
            padding: 20px 0;
            margin: 0 -15px 30px;
        }
        img {
            display: none;
            position: absolute;
            top: 30px;
            @include media(414) {
                display: block;
            }
        }
    }
}