.s-bend {
    padding: 30px 0 50px;
    background-color: $gray;
    @include media(768) {
        padding: 30px 0 0;
    }
}

.bend {
    display: flex;
    flex-wrap: wrap;
    @include media(768) {
        flex-direction: column-reverse;
    }
    & > * {
        &:not(:last-child) {
            margin-right: 20px;
            @include media(768) {
                margin-right: 0;
            }
        }
    }
    & > img {
        display: none;
        @include media(768) {
            display: block;
        }
    }
    &__item {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 50px;
        text-align: center;
        justify-content: center;
        height: 100%;
        border-radius: 5px;
        background-color: $white;
        span {
            color: $gray;
            font-size: 19px;
            line-height: 23px;
        }
    }
    &__col {
        width: 100%;
        color: $white;
        flex: 1;
        .vipProccess__item {
            width: 100%;
            max-width: 100%;
        }
        h2 {
            font-weight: bold;
            font-size: 22px;
            line-height: 27px;
            margin-bottom: 20px;
            @include media(768) {
                text-align: center;
            }
            @include media(414) {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 10px;
            }
        }
        p {
            font-size: 17px;
            line-height: 21px;
            &:not(:last-child) {
                margin-bottom: 20px;
                @include media(414) {
                    margin-bottom: 10px;
                }
            }
            @include media(414) {
                font-size: 14px;
                line-height: 19px;
            }
 
        }
        &:nth-child(2) {
            max-width: 425px;
            @include media(768) {
                margin: 0 auto 30px;
                height: 220px;
            }
            @include media(414) {
                margin: 0 0 20px;
            }
        }
        &:last-child {
            @include media(768) {
                margin-bottom: 20px;
            }
        }
    }
}