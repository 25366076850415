.s-exx {
    padding: 30px 0 60px 0;
    @include media(768) {
        padding: 30px 0;
    }
    @include media(414) {
        padding: 7px 0 0;
    }
}

.qwiz {
    @include media(768) {
        margin-left: -15px;
        margin-right: -15px;
    }
    &-step {
        &__item {
            p {
                display: none;
                color: $blue;
                max-width: 300px;
                margin: 13px auto 16px;
                @include media(414) {
                    display: block;
                }
            }
        }
    }
    &-top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media(930) {
            width: 900px;
        }
        &__inner {
            overflow: auto hidden;
            padding: 5px 0 20px 0;
            
        }
        &__item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 80px;
            cursor: pointer;
            height: 57px;
            @include media(768) {
                width: 72px;
                height: 52px;
            }
            label {
                width: 100%;
                height: 100%;
                position: absolute;
                cursor: pointer;
                &:after {
                    content: '';
                    position: absolute;
                    transition: all .3s linear;
                    z-index: -1;
                    border-radius: 5px;
                    width: 100%;
                    height: 100%;
                    border: 1px solid $orange;
                    background-color: $blue;
                }
                &:before {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    transition: all .3s linear;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -22px;
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 15px 20px 15px;
                    border-color: transparent transparent #7ea0ad transparent;
                }
            }
            input {
                position: absolute;
                opacity: 0;
                visibility: hidden;
                pointer-events: none;
                &:checked {
                    & + label {
                        &:after {
                            background-color: $orange;
                            border-color: $blue;
                        }
                        &:before {
                            opacity: 1;
                        }
                        
                    }
                }
                
            }
            img {
                transition: all .3s linear;
            }
            &:hover {
                img {
                    transform: scale(1.1);
                }
            } 
        } 
    }
    &__step {
        width: 100%;
        color: $white;
        background-color: #fff;
        border: 2.5px solid #7EA0AD;
        border-radius: 5px;
        @include media(414) {
            margin-bottom: 35px;
        }
        &_top {
            position: relative;
            top: -1px;
            left: -1px;
            height: 270px;
            width: calc(100% + 2px);
            background-color: $blue;
            padding: 30px 110px 0;
            @include media(768) {
                padding: 30px 0 0;
                height: auto;
            }
        }
        &_body {
            position: relative;
            height: 200px;
            color: $blue;
            padding: 20px 110px 0;
            @include media(768) {
                display: flex;
                justify-content: space-around;
                padding: 20px 20px 90px 20px;
                height: auto;
            }
            @include media(414) {
                flex-wrap: wrap;
                justify-content: space-between;
                padding: 20px 20px 70px 20px;
            }
            @include media(360) {
                justify-content: center;
            }
            p {
                color: $blue;
            }
        }
        p {
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            @include media(414) {
                font-size: 12px;
                line-height: 15px;
            }
        }
        h3 {
            font-size: 18px;
            text-align: center;
            line-height: 22px;
            color: $white;
        }
        &_two {
            @include media(414) {
                margin-bottom: 40px;
                border: none;
            }
        }
        &_btn {
            width: 100%;
            position: absolute;
            bottom: -25px;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            p {
                display: none;
                @include media(414) {
                    display: inline-block;
                    text-align: left;
                    line-height: 16px;
                }
                @include media(370) {
                    order: 1;
                    text-align: center;
                }
                a {
                    color: $blue;
                    white-space: nowrap;
                }
            }
            @include media(768) {
                position: static;
                transform: translateX(0);
            }
            @include media(414) {
                justify-content: space-between;
            }
            @include media(370) {
                flex-direction: column;
            }

        }
        &_one {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;
            height: 470px;
            @include media(414) {
                height: auto;
                padding: 20px 0;
            }
            &:after {
                content: '';
                position: absolute;
                top: -1px;
                right: -1px;
                left: -1px;
                height: 270px;
                width: calc(100% + 2px);
                background-color: #7EA0AD;
                @include media(414) {
                    height: 140px;
                }
            }
            .Button__qwiz {
                max-width: 390px;
                padding: 14px 27px 14px 18px;
                img {
                    @include media(414) {
                        width: 16px;
                        margin-right: 10px;
                    }
                   
                }
                span {
                    @include media(414) {
                        font-size: 14px;
                    }
                }
                @include media(414) {
                    max-width: 100%;
                    width: auto;
                    padding: 8px;
                }
            }
        }  
        &_three {
            .qwiz__step_top {
                p {
                    margin: 10px 0 20px 0;
                    @include media(414) {
                        margin: 10px 10px 20px 10px;
                    }
                }
            }
            .qwiz__step_body {
                padding: 40px 110px 0;
                @include media(768) {
                    padding: 20px 20px 90px 20px;
                }
            }
        }
        &_four {
            display: block;
            &:after {
                display: none;
            }
        }
        &_five {
            @include media(414) {
                margin-bottom: 10px;
            }
            .qwiz__step_top {
                padding: 30px 55px 8px;
                @include media(768) {
                    padding: 30px 10px 8px;
                }
            }
            .qwiz__step_body {
                padding: 10px 55px; 
                @include media(768) {
                    display: flex;
                    flex-direction: column;
                    padding: 10px 10px 20px 10px;
                }
            }
            
        }
    }
}

.glasses {
    display: flex;
    flex-wrap: wrap;
    margin: 37px -15px;
    @include media(768) {
        justify-content: center;
        margin: 20px -15px 0 -15px;
    }
    &__item {
        max-width: 20%;
        color: $white;
        padding: 0 15px;
        @include media(768) {
            margin-bottom: 20px;
            max-width: 170px;
        }
        @include media(414) {
            max-width: 120px;
        }
        label {
            position: relative;
            display: flex;
            cursor: pointer;
            flex-direction: column;
            img {
                max-width: 100%;
            }
            &:after {
                content:'';
                position: absolute;
                left: -10px;
                top: -10px;
                background-color: #F5F5F5;
                @include size(26px);
                border: 1px solid #7EA0AD;
                border-radius: 3px;
                @include media(414) {
                    left: -6px;
                    top: -6px;
                    @include size(20px);
                }
            }
            &:before {
                content:'';
                position: absolute;
                transform: scale(0);
                transition: all .3s linear;
                z-index: 1;
                left: -6px;
                top: -6px;
                background-color: #F4B955;
                @include size(18px);
                border-radius: 50%;
                @include media(414) {
                    left: -3px;
                    top: -3px;
                    @include size(14px);
                }
            }
        }
        input {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            &:checked {
                & + label {
                    &:before {
                        transform: scale(1);
                    }
                }
            }
        }
        span {
            width: 120px;
            text-align: center;
            margin-top: 6px;
            font-size: 15px;
            line-height: 18px;
            @include media(414) {
                width: auto;
                font-size: 10px;
                margin-left: -12px;
            }
        }
    }
}

.extra {
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    @include media(768) {
        flex-direction: column;
        padding: 0;
    }
    &__item {
        padding-left: 34px;
        @include media(414) {
            padding-left: 28px;
        }
        &:not(:last-child) {
            @include media(768) {
                margin-bottom: 18px;
            }
        }
        input {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            &:checked {
                & + label {
                    &:before {
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
        label {
            display: block;
            position: relative;
            cursor: pointer;
            &:after {
                content:'';
                position: absolute;
                left: -34px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #F5F5F5;
                @include size(26px);
                border: 1px solid $blue;
                border-radius: 3px;
                @include media(414) {
                    @include size(20px);
                    left: -28px;
                }
            }
            &:before {
                content:'';
                position: absolute;
                transform: translateY(-50%) scale(0);
                transition: all .3s linear;
                z-index: 1;
                left: -30px;
                top: 50%;
                background-color: $blue;
                @include size(18px);
                border-radius: 50%;
                @include media(414) {
                    @include size(14px);
                    left: -25px;
                }
            }
        }
        &_text {
            display: block;
            font-size: 16px;
            line-height: 20px;
            color: $blue;
            @include media(768) {
                font-size: 14px;
                line-height: 16px;
            }
            @include media(414) {
                font-size: 12px;
                line-height: 14px;
            }
            span {
                font-size: 14px;
                @include media(768) {
                    font-size: 12px;
                }
                @include media(414) {
                    font-size: 10px;
                }
            }
        }
    }
}

.navigate {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    @include media(414) {
        bottom: 25px;
    }
    ul {
        display: flex;
        align-items: center;
        justify-content: center;
        li {
            border: 1px solid #D4E2E8;
            border-radius: 5px;
            text-align: center;
            cursor: pointer;
            line-height: 32px;
            color: $blue;
            @include size(32px);
            &.active {
                background-color: $blue;
                color: $white;
                border-color: #F4B955;
            }
            &:not(:last-child) {
                margin-right: 26px;
            }
            @include media(414) {
                @include size(24px); 
                line-height: 24px;
            }
        }
    }
}

.fittings {
    @include media(768) {
        padding-bottom: 20px;
    }
    p {
        color: $blue;
    }
    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        @include media(414) {
            flex-wrap: wrap;
        }
    }
    &__slider {
        &_item {
            position: relative;
            z-index: 1;
        }
    }
    &__item {
        @include media(414) {
            flex: 0 0 calc(50% - 10px);
            text-align: center;
            overflow: hidden;
        }
        input {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            & + label {
                display: inline-block;
                background: #FFFFFF;
                padding: 12px;
                cursor: pointer;
                transition: all .3s linear;
                border: 2px solid #F4B955;
                border-radius: 10px;
                font-size: 13px;
                font-weight: 600;
                line-height: 16px;
                text-align: center;
                color: #668997;
                @include media(414) {
                    width: 100%;
                    font-size: 10px;
                    padding: 8px;
                    line-height: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    
                    
                }
            }
            &:checked {
                & + label {
                    background-color: #F4B955;
                    color: $white;
                    border-color: $white;
                }
            }
        }
        &:not(:last-child) {
            margin-right: 10px;
            @include media(414) {
                margin-right: 0;
            }
        }
        &:not(:nth-child(2n)) {
            @include media(414) {
                margin-right: 10px;
            }
        }
        &:first-child,
        &:nth-child(2) {
            @include media(414) {
                margin-bottom: 10px;
            }
        }
    }
}

.details {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    justify-content: center;
    &__item {
        padding-left: 34px;
        margin-bottom: 50px;
        @include media(768) {
            padding-left: 30px;
        }
        @include media(414) {
            margin-bottom: 5px;
            flex: 0 0 160px;
        }
        @include media(320) {
            flex: 0 0 150px;
        }
        & > input {
            position: absolute;
            visibility: hidden;
            opacity: 0;
            &:checked {
                & + label {
                    &:before {
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
        label {
            position: relative;
            cursor: pointer;
            @include media(768) {
                font-size: 14px;
            }
            @include media(414) {
                font-size: 11px;
            }
            &:after {
                content:'';
                position: absolute;
                left: -34px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #F5F5F5;
                @include size(26px);
                border: 1px solid $blue;
                border-radius: 3px;
                @include media(768) {
                    left: -30px;
                    @include size(23px);
                }
            }
            &:before {
                content:'';
                position: absolute;
                transform: translateY(-50%) scale(0);
                transition: all .3s linear;
                z-index: 1;
                left: -30px;
                top: 50%;
                background-color: #F4B955;
                @include size(18px);
                border-radius: 50%;
                @include media(768) {
                    @include size(16px);
                    left: -27px;
                }
            }
        }
    }
    &__col {
        margin-right: 60px;
        @include media(414) {
            margin-right: 0;
            display: flex;
            width: calc(100% - 15px);
            justify-content: space-between;
        }
        &:nth-child(2) {
            @include media(414) {
                margin-bottom: 60px;
            }
           
        }
        &:nth-child(3n) {
            margin-right: 0;
            @include media(414) {
                order: -1;
            }
        }
    }
    &__dop {
        position: relative;
        display: flex;
        align-items: center;
        margin-top: 3px;
        justify-content: flex-end;
        &_arrow {
            position: absolute;
            right: 0;
            cursor: pointer;
            top: 5px;
            width: 0;
            height: 0;
            transition: all .3s linear;
            border-style: solid;
            border-width: 15px 12px 0 12px;
            border-color: #D4E2E8 transparent transparent transparent;
            &.active {
                border-width: 0 12px 15px 12px;
                border-color: transparent transparent #D4E2E8 transparent;
                @include media(414) {
                    border-width: 0 10px 12px 10px;
                }
            }
            @include media(414) {
                right: -5px;
                top: 10px;
                border-width: 12px 10px 0 10px;
            }
        }
        & > * {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
        span {
            @include media(320) {
                font-size: 10px;
            }
        }
        span,
        li {
            font-size: 14px;
            line-height: 17px;
            color:#D4E2E8;
            @include media(768) {
                font-size: 12px;
            }
        }
        ul {
            position: absolute;
            z-index: 99;
            border-radius: 5px;
            border: 1px solid #D4E2E8;
            background-color: #7ea0ad;
            left: -33px;
            padding: 2.5px 0;
            height: 30px;
            transition: height .3s linear;
            overflow: hidden;
            top: 5px;
            @include media(768) {
                left: -28px;
            }
            @include media(414) {
                z-index: 9999;
            }
            &.active {
                height: auto;
                overflow: auto;
            }
            li {
                cursor: pointer;
                padding: 2.5px 10px;
                transition: all .3s linear;
                &:not(:last-child) {
                    margin-bottom: 3px;
                }
                &:hover {
                    color: $white;
                }
                @include media(414) {
                    padding: 2px 5px;
                }
                
            }
        }
        input {
            width: 55px;
            height: 25px;
            font-size: 14px;
            text-align: center;
            color: $white;
            background: #7EA0AD;
            border: 1px solid #D4E2E8;
            border-radius: 5px;
            @include media(414) {
                width: 40px;
                height: 23px;
            }
            @include media(320) {
                width: 30px;
            }
        }
    }
}

.question {
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    @include size(50px);
    border-radius: 50%;
    background-color: #D4E2E8;
    @include media(768) {
        @include size(40px);
        bottom: -20px;
    }
    @include media(414) {
        @include size(35px);
        bottom: -17.5px;
    }
    &__icon {
        margin: auto;
        @include size(30px);
        fill: #A2BEC9;
        @include media(768) {
            @include size(25px);
        }
        @include media(414) {
            @include size(20px);
        }
    }
    &__inner {
        display: flex;
        margin-top: 10px;
        justify-content: space-between;
        @include media(768) {
            margin-top: 20px;
        }
        @include media(414) {
            flex-wrap: wrap;
        }
    }
    &__item {
        cursor: pointer;
        position: relative;
        z-index: 999;
        margin-bottom: 15px;
        @include media(414) {
            display: flex;
            align-items: center;
        }
        p {
            span {
                @include media(414) {
                    display: none;
                }
            }
        }
        .glass__info {
            width: 700px;
            @include media(768) {
                max-width: 500px;
            }
            @include media(560) {
                max-width: 330px;
            }
            @include media(500) {
                display: none;
            }
            &_right {
                left: auto;
                right: 10%;
                &:after {
                    left: auto;
                    right: 10px;
                }
                .glass__info_after {
                    left: -50px;
                    right: auto;
                }
            }
        }
        &:hover {
            .glass__info {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                bottom: calc(115% + 20px);
            }
        }
    }
    &__col {
        @include media(768) {
            max-width: 40%;
        }
        @include media(414) {
            max-width: 100%;
        }
        p {
            font-size: 14px;
            line-height: 17px;
            color: $blue;
            text-align: left;
            
        }
    }
    &_img {
        display: none;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        @include size(20px);
        flex: 0 0 auto;
        margin-right: 7px;
        background-color: #D4E2E8;
        @include media(414) {
            display: flex;
        }
        &_icon {
            @include size(10px);
            fill: #A2BEC9;
        }
    }
}

.timing {
    margin: 8px 0 20px 0;
    h4 {
        margin-bottom: 12px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 500;
        @include media(414) {
            margin-bottom: 10px;
            font-size: 16px;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        & > * {
            &:not(:last-child) {
                margin-right: 20px;
            }
        }
    }
    &__input {
        padding-left: 36px;
        @include media(414) {
            padding-left: 26px;
        }
        input {
            position: absolute;
            opacity: 0;
            visibility: hidden;
            &:checked {
                & + label {
                    &:before {
                        transform: translateY(-50%) scale(1);
                    }
                }
            }
        }
        label {
            position: relative;
            cursor: pointer;
            @include media(414) {
                font-size: 12px;
            }
            &:after {
                content:'';
                position: absolute;
                left: -36px;
                top: 50%;
                transform: translateY(-50%);
                background-color: #F5F5F5;
                @include size(26px);
                border: 1px solid $blue;
                border-radius: 3px;
                @include media(414) {
                    @include size(20px);
                    left: -26px;
                }
            }
            &:before {
                content:'';
                position: absolute;
                transform: translateY(-50%) scale(0);
                transition: all .3s linear;
                z-index: 1;
                left: -32px;
                top: 50%;
                background-color: #F4B955;
                @include size(18px);
                border-radius: 50%;
                @include media(414) {
                    @include size(14px);
                    left: -23px;
                }
            }
        }
    }
    &__text {
        font-weight: 600;
        background: #97B0BA;
        padding: 12px 15px;
        border: 1px solid #BFD7E0;
        border-radius: 5px;
        @include media(414) {
            padding: 8px;
            font-size: 12px;
            line-height: 14px;
            max-width: 160px;
        }
        @include media(320) {
            max-width: 150px;
        }
    }
    ul {
        li {
            font-size: 17px;
            line-height: 21px;
            font-weight: 400;
            @include media(414) {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }
    }
}

.payment {
    color: $blue;
    @include media(768) {
        margin-bottom: 20px;
    }
    h4 {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 7px;
        font-weight: 500;
    }
    ul {
        li {
            font-size: 16px;
            line-height: 20px;
            &:not(:last-child) {
                margin-bottom: 4px;
                @include media(414) {
                    margin-bottom: 12px;
                }
            }
            @include media(414) {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }
}

.response {
    display: none;
    max-width: 140px;
    padding: 10px;
    cursor: pointer;
    text-align: center;
    border: 1px solid #D4E2E8;
    border-radius: 5px;
    width: 100%;
    .question {
        position: static;
        flex: 0 0 auto;
        transform: translateX(0);
        @include size(40px);
        margin: 0 auto 6px;
        &__icon {
            @include size(20px);
        }
        @include media(360) {
            margin-right: 10px;
            margin-bottom: 0;
        }
    }
    span {
        font-size: 12px;
        line-height: 14px;
        display: inline-block;
        opacity: .7;
        @include media(414) {
            font-size: 10px;
        }
    }
    @include media(768) {
        display: block;
    }
    @include media(414) {
        max-width: 120px;
    }
    @include media(360) {
        max-width: 190px;
        margin-top: 20px;
        display: flex;
        align-items: center;
    }
}