.Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    user-select: none;
    border-radius: 10px;
    padding: 11px 20px;
    background-color: #86A8B5;
    cursor: pointer;
    transition: border .3s linear;
    color: $white;
    border: 3px solid rgba(244, 185, 85, 0.52);
    &._preloader {
        position: relative;
        left: 0;
        top: 0;
        color: $orange;
        bottom: 0;
        right: 0;
        background-color: $orange;
        span {
            color: $orange;
        }
    }
    &__form {
        width: 100%;
        font-size: 16px;
        background-color: #F4B955;
        border: none;
        &:hover {
            background-color: #eca938;
        }
    }
    &__orange {
        background-color: #F4B955;
        padding: 10px 22px;
        transition: all .3s linear;
        box-shadow: 0px 0px 10px #F5BA54;
        @include media(414) {
            padding: 10px 13px;
        }
        &:hover {
            box-shadow: none;
        }
    }
    &__qwiz {
        width: 100%;
        max-width: 350px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -25px;
        background-color: $orange;
        border: 2px solid $blue;
        border-radius: 10px;
        @include media(768) {
            width: auto;
            max-width: 100%;
            font-size: 12px;
            bottom: -21px;
            padding: 12px 25px;
        }
        @include media(414) {
            width: 100%;
            max-width: 230px;
            padding: 10px 15px;
            bottom: -20px;
        }
        span {
            &:last-child {
                display: none;
                @include media(414) {
                    display: block;
                }
            }
            &:nth-child(2) {
                @include media(414) {
                    display: none;
                }
            }
        }
    }
    &_option {
        @include media(414) {
            position: static;
            transform: translateX(0);
        }
    }
    &_qwize {
        display: none;
        box-shadow: none;
        border: 2px solid $blue;
        margin-top: 16px;
        @include media(414) {
            display: block;
        }
    }
    &__payment {
        margin-top: 20px;
    }
    img {
        max-width: 23px;
        margin-right: 15px;
        @include media(414) {
            margin-right: 10px;
        }
    }
    span {
        font-size: 17px;
        white-space: nowrap;
        @include media(414) {
            font-size: 14px;
        }
    }
    &:hover {
        border-color: rgba(244, 185, 85, 0.8);
    }
    &__step {
        &_five {
            margin: 0;
            height: 50px;
            box-shadow: none;
            border: 1px solid $blue;
            span {
                @include media(768) {
                    font-size: 12px;
                    white-space: nowrap;
                }
            }
            &:first-child,
            &:nth-child(2) {
                @include media(414) {
                    margin-bottom: 15px;
                }
            }
            &:not(:last-child) {
                margin-right: 20px;
                @include media(370) {
                    margin-right: 0;
                }
            }
            @include media(768) {
                padding: 10px;
                height: auto;
            }
            @include media(370) {
                margin-bottom: 10px;
            }
        }
    }
    &-270 {
        width: 100%;
        max-width: 270px;
    }
}

a.Button {
    display: inline-flex;
}

.btn {
    display: inline-flex;
    align-items: center;
    width: 100%;
    text-align: center;
    transition: all .3s;
    color: $white;
    height: 50px;
    letter-spacing: 0.02em;
    cursor: pointer;
    border: 2px solid transparent;
    line-height: 50px;
    font-weight: 500;
    background: #F4B955;
    border-radius: 10px;
    span {
        font-size: 14px;
        line-height: 17px;
        margin: auto;
        @include media(768) {
            font-weight: 400;
        }
    }
    &:hover {
        border-color: rgba(244, 185, 85, 0.52);
        background: $gray;
    }
}
.btn-220 {
    max-width: 220px;
    @include media(768) {
        max-width: 200px;
    }
}
.btn-auto {
    width: auto;
    padding: 0 20px;
    img {
        margin-right: 14px;
    }
    span  {
        font-size: 17px;
    }
}

.callback {
    width: 120px;
    height: 57px;
    border-radius: 5px;
    border: 2px solid $blue;
    cursor: pointer;
    background-color: $orange;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    transition: all .3s linear;
    &:hover {
        background-color: $blue;
    }
    @include media(768) {
        width: 100px;
        height: 52px;
    }
}