.tooltip {
    visibility: hidden;
    opacity: 0;
    white-space: nowrap;
    pointer-events: none;
    text-align: center;
    letter-spacing: 0.04em;
    width: 160px;
    padding: 10px 20px;
    background: #333;
    color: #ffffff;
    position: absolute;
    top: -80px;
    left: calc(100% - 85px);
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 3px rgba(56, 54, 54, 0.86);
    @include media(768) {
      display: none;
    }
}

.tooltip::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 12px 12.5px 0 12.5px;
    border-color: #333 transparent transparent transparent;
    position: absolute;
    top: 95%;
    left: 50%;
    transform: translateX(-50%);
}
  
.fixedMenu__center a:hover .tooltip {
    visibility: visible;
    pointer-events: all;
    opacity: 1;
    transition: .3s linear;
    animation: odsoky 1s ease-in-out infinite  alternate;
}

.top:hover { transform: translateY(-6px);  }

@keyframes odsoky {
    0%{
      transform: translateY(6px);	
    }
  
    100%{
      transform: translateY(1px);	
    }
  
}