.s-questions {
    padding: 30px 0 50px;
    background-color: #fff;
    @include media(768) {
        padding: 30px 0;
    }
    @include media(414) {
        padding: 15px 0 25px;
    }
    .accordeon {
        @include media(768) {
            margin-bottom: 0;
        }
    }
}

.questions {
    &-top {
        &.active {
            &:before {
                opacity: 1;
            }
        }
    }
}