.s-examples {
    padding: 30px 0 60px 0;
    background-color: #fff;
    @include media(414) {
        padding: 0 0 30px 0;
    }
}

.examples {
    &__thanks {
        font-size: 19px;
        line-height: 23px;
        text-align: center;
        max-width: 700px;
        margin: 0 auto 40px;
        font-weight: 400;
        color: $blue;
        @include media(414) {
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            margin: 0 auto 20px;
        }
    }
}

.accordeon {
    position: relative;
    z-index: 1;
    color: $blue;
    margin-bottom: 45px;
    @include media(414) {
        margin-bottom: 20px;
    }
    p {
        font-size: 19px;
        line-height: 28px;
        font-weight: 400;
        user-select: none;
        @include media(414) {
            font-size: 14px;
            line-height: 19px;
        }
    }
    &__item {
        position: relative;
        width: 100%;
        border: 4px solid transparent;
        background-color: #fff;
        border-radius: 2.5px;
        &:not(:last-child) {
            margin-bottom: 20px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -6px;
            right: -6px;
            border-radius: 5px;
            top: -6px;
            bottom: -6px;
            background: #f4b955;
            background: linear-gradient(175deg, #f4b955 0%, #91b7c6 100%);
        }
    }
    &__top {
        display: flex;
        align-items: center;
        position: relative;
        padding: 15px 60px 15px 22px;
        font-size: 19px;
        line-height: 23px;
        cursor: pointer;
        @include media(414) {
            padding: 5px 40px 5px 10px;
            font-size: 12px;
            min-height: 40px;
            line-height: 16px;
        }
        &.active {
            &:after {
                border-width: 0 15px 20px 15px;
                border-color: transparent transparent #7ea0ad transparent;
                @include media(414) {
                    border-width: 0 12px 15px 12px;
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            pointer-events: none;
            right: 22px;
            top: 45%;
            transform: translateY(-50%);
            width: 0;
            transition: all .3s linear;
            height: 0;
            border-style: solid;
            border-width: 20px 15px 0 15px;
            border-color: #7ea0ad transparent transparent transparent;
            @include media(414) {
                border-width: 15px 12px 0 12px;
                right: 10px;
            }
        }
        &:before {
            content: '';
            position: absolute;
            opacity: 0;
            left: -4px;
            bottom: 0;
            transition: all .3s linear;
            height: 2px;
            width: calc(100% + 8px);
            background: #f4b955;
            background: linear-gradient(175deg, #f4b955 0%, #91b7c6 100%);
        }
    }
    &__body {
        display: none;
        padding: 10px 32px 20px 32px;
        @include media(768) {
            padding: 10px 20px 20px;
        }
        @include media(414) {
            padding: 10px;
        }
        ul {
            li {
                font-weight: 400;
                line-height: 24px;
                user-select: none;
                &:not(:last-child) {
                    margin-bottom: 5px;
                }
                @include media(414) {
                    line-height: 20px;
                    font-size: 14px;
                }

            }
        }
        &_list {
            display: flex;
            margin-bottom: 20px;
            justify-content: center;
            @include media(414) {
                flex-wrap: wrap;
                margin-bottom: 0;
            }
        }
        &_item {
            width: 25%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 10px;
            height: 290px;
            border: 1px solid #FADFB1;
            @include media(768) {
                height: 220px;
            }
            @include media(414) {
                width: calc(50% - 8px);
                height: 170px;
                margin-bottom: 16px;
            }
            &:not(:last-child) {
                margin-right: 26px;
                @include media(768) {
                    margin-right: 16px;
                }
            }
            &:nth-child(2n) {
                @include media(414) {
                    margin-right: 0;
                }
            }
        }
    }
}