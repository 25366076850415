.transition {
    display: flex;
    position: fixed;
    z-index: 9999999;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
    pointer-events: none;
}

.transition li {
    flex: 1;
    transform: scaleY(0);
    background: rgba(126,160,173, .8);
}