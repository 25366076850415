.s-vipzone {
    padding: 50px 0 30px;
    @include media(414) {
        padding: 70px 0 30px;
    }
    &_seven {
        @include media(414) {
            padding: 30px 0;
        }
    }
    &_four {
        .lineGroup {
            display: none;
            @include media(414) {
                display: block;
            }
            &__item {
                height: 24px;
            }
        }
    }
}


.vipzone {
    &__list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        @include media(768) {
            margin: 0 50px 60px;
        }
        @include media(414) {
            margin: 0 0 30px;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -2;
            width: calc(100% + 130px);
            top: 50%;
            left: -65px;
            transform: translateY(-50%);
            height: 250px;
            background-color: $blue;
        }
    }
    button {
        margin-top: 30px;
        @include media(414) {
            margin-top: 25px;
        }
        &:nth-last-child(2) {
            @include media(414) {
                display: none;
            }
        }
        &:last-child {
            display: none;
            @include media(414) {
                display: flex;
            }
        }
    }
    &_caption {
        position: relative;
        display: flex;
        padding: 3px;
        border-radius: 10px;
        justify-content: center;
        background: rgb(231,215,184);
        background: linear-gradient(180deg, rgba(231,215,184,1) 0%, rgba(139,173,186,1) 45%, rgba(145,183,198,1) 100%);
        img {
            width: 100%;
            object-fit: cover;
            border-radius: 7px;
        }
        a {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            &:after {
                content: '';
                position: absolute;
                border-radius: 7px;
                left: 0;
                background: rgba(0,0,0, .5);
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__item {
        max-width: calc(50% - 20px);
        width: calc(50% - 20px);
        height: 300px;
        @include media(768) {
            max-width: 100%;
            width: 100%;
        }
        @include media(414) {
            height: 200px;
        }
        &:not(:last-child) {
            margin-right: 40px;
            @include media(768) {
                margin-right: 0;
                margin-bottom: 60px;
            }
            @include media(414) {
                margin-bottom: 35px;
            }
        }
        &:last-child {
            &:after {
                right: -10px;
                left: auto;
                @include media(414) {
                    right: -7px;
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 10px;
            background-color: $orange;
            width: 75%;
            height: calc(100% + 20px);
            @include media(414) {
                left: -7px;
                height: calc(100% + 14px);
            }
        }
    }
    p {
        font-size: 22px;
        line-height: 27px;
        text-align: center;
        color: $gray;
        user-select: none;
        span {
            font-weight: 600;
        }
        @include media(768) {
            font-size: 18px;
            line-height: 24px;
        }
        @include media(414) {
            font-size: 14px;
            line-height: 19px;
            text-align: left;
        }
    }
    &__title {
        span {
            text-transform: uppercase;
            &:first-child {
                display: block;
            }
            &:nth-child(2) {
                display: block;
            }
        }
        @include media(414) {
            margin-bottom: 30px;
        }
    }
}

.vipGallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    margin: 50px 0;
    @include media(768) {
        margin: 25px 0 0;
    }
    h3 {
        display: none;
        font-weight: 600;
        font-size: 24px;
        line-height: 22px;
        margin-bottom: 30px;
        text-align: center;
        color: $gray;
        @include media(768) {
            display: block;
        }
        @include media(414) {
            font-size: 19px;
            margin-bottom: 20px;
        }
    }
    &__inner {
        display: flex;
        flex-wrap: wrap;
        @include media(414) {
            display: none;
        }
    }
    & + p {
        @include media(768) {
            color: $white;
        }
    }
    &__slider {
        display: none;
        @include media(414) {
            display: block;
        }
        .swiper-container {
            max-width: 500px;
        }
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        top: calc(50% - 15px);
        transform: translateY(-50%);
        width: 1000%;
        height: 150px;
        background-color: $gray;
        @include media(768) {
            left: -15px;
        }
        @include media(414) {
            display: none;
        }
    }
    &__item {
        position: relative;
        z-index: 1;
        height: 220px;
        max-width: calc(25% - 22.5px);
        width: calc(25% - 22.5px);
        margin-bottom: 30px;
        &:not(:last-child) {
            margin-right: 30px;
            @include media(768) {
                margin-right: 0;
            }
            &:after {
                @include media(414) {
                    display: block;
                }
            }
        }
        &:not(:nth-child(2n)) {
            @include media(768) {
                margin-right: 30px;
            }
            @include media(414) {
                margin-right: 0;
            }
        }
        &:nth-child(4n) {
            margin-right: 0;
        }
        &:after {
            content: '';
            display: none;
            position: absolute;
            bottom: -82.5px;
            z-index: -1;
            width: 1000%;
            height: 145px;
            background-color: $blue;
        }
        &:nth-child(2) {
            &:after {
                background-color: $orange;
            }
        }
        &:last-child {
            @include media(414) {
                margin-bottom: 15px;
            }
        }
        @include media(768) {
            max-width: calc(50% - 15px);
            width: calc(50% - 15px);
        }
        @include media(414) {
            max-width: 300px;
            width: 100%;
        }
    }
    &_after {
        margin-bottom: 30px;
        @include media(414) {
            margin-bottom: 10px;
        }
        &:after {
            height: 375px;
            left: 50%;
            bottom: 0;
            top: auto;
            transform: translateX(-50%);
            @include media(414) {
                display: none;
            }

        }
        & + p {
            @include media(768) {
                color: $blue;
            }
            @include media(414) {
                text-align: center;
            }
            span {
                @include media(414) {
                    display: block;
                    margin-bottom: 8px;
                }
            }
        }
    }
    &__cat {
        width: calc(100% + 42px);
        display: none;
        justify-content: space-between;
        position: absolute;
        z-index: -1;
        top: -27px;
        img {
            max-width: 50px;
        }
        @include media(414) {
            display: flex;
        }
    }
}

.vinDisel {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 50px 0 80px;
    height: 300px;
    @include media(768) {
        flex-direction: column;
        height: auto;
        margin: 50px 0 20px;
    }
    @include media(414) {
        margin: 20px 0;
    }
    &:after {
        content: '';
        position: absolute;
        z-index: -1;
        right: 0;
        width: 1000%;
        height: 150px;
        background-color: $gray;
        top: 50%;
        transform: translateY(-50%);
        @include media(768) {
            left: -15px;
            height: 170%;
        }
        @include media(414) {
            top: 48%;
        }
    }
    &__item {
        position: relative;
        z-index: 1;
        width: calc(25% - 20px);
        max-width: calc(25% - 20px);
        height: 200px;
        margin-right: 30px;
        @include media(768) {
            width: 50%;
            max-width: 300px;
            margin-right: 0;
            margin-bottom: 25px;
        }
        @include media(414) {
            width: 100%;
            max-width: 250px;
        }
        &:first-child {
            align-self: flex-start;
            &:after {
                left: 3px;
            }
            &:before {
                right: -50px;
                bottom: 20px;
            }
        }
        &:nth-child(2) {
            align-self: flex-end;
            &:after {
                right: 3px;
            }
            &:before {
                left: -50px;
                bottom: 20px;
                @include media(414) {
                    top: 20px;
                    bottom: auto;
                }
            }
        }
        &:after,
        &:before {
            content: '';
            display: none;
            position: absolute;
            z-index: -1;
            background-color: $orange;
            @include media(768) {
                display: block;
            }
        }
        &:after {
            width: 1000%;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
        }
        &:before {
            @include size(16px);
            border-radius: 3px;
        }
    }
    &__move {
        position: relative;
        width: calc(50% - 20px);
        max-width: calc(50% - 20px);
        height: 300px;
        @include media(768) {
            width: 100%;
            max-width: 400px;
        }
    }
}

.vipProccess {
    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $gray;
        margin-bottom: 30px;
        @include media(768) {
            color: $white;
            margin-bottom: 20px;
        }
        @include media(414) {
            font-size: 19px;
            line-height: 24px;
            margin-bottom: 10px;
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: 50px 0;
        @include media(414) {
            margin: 20px 0;
        }
    }
    &__item {
        position: relative;
        z-index: 1;
        width: calc(50% - 25px);
        max-width: calc(50% - 25px);
        height: 300px;
        @include media(414) {
            width: 100%;
            max-width: 100%;
            height: 200px;
        }
        &:first-child {
            &:after,
            &:before {
                display: none;
            }
        }
        &:not(:last-child) {
            margin-right: 50px;
            @include media(414) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }
    &_line {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1000%;
            height: 150px;
            background-color: $gray;
            @include media(414) {
                display: none;
            }
        }
    }
    &_line2 {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            z-index: -2;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            width: 1000%;
            height: 60px;
            background-color: $gray;
            @include media(414) {
                bottom: 30px;
                top: auto;
                left: -15px;
                transform: translate(0,0);
                height: 140px;
            }
        }
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            @include size(16px);
            background-color: $orange;
            border-radius: 3px;
            @include media(414) {
                display: none;
            }
            
        }
        .vipProccess__item {
            &:before {
                content: '';
                display: block;
                position: absolute;
                z-index: -1;
                top: 50%;
                transform: translateY(-50%);
                @include size(16px);
                background-color: $orange;
                border-radius: 3px;
            }
            &:first-child {
                &:before {
                    left: -42px;
                }
            }
            &:last-child {
                &:before {
                    right: -42px;
                }
            }
        }
    }
    p:nth-child(2) {
        @include media(768) {
            color: $white;
        }
    }
    &_psevdo {
        @include center;
        z-index: -1;
        width: 100%;
        &:after,
        &:before {
            content: '';
            display: none;
            position: absolute;
            width: 50%;
            z-index: -1;
            top: 50%;
            transform: translateY(-50%);
            height: 145px;
            @include media(414) {
                display: block;
            }
        }
        &:after {
            background-color: $orange;
            left: -15px;
        }
        &:before {
            background-color: $blue;
            right: -15px;
        }
    }
}

.vipCaption {
    margin: 50px 0 60px;
    @include media(768) {
        margin: 40px 0 20px;
    }
    @include media(414) {
        margin: 25px 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-left: -25px;
        margin-right: -25px;
        @include media(414) {
            margin-left: 0;
            margin-right: 0;
        }
        p {
            width: 100%;
            margin: 33px 0 25px;
            @include media(414) {
                text-align: center;
                margin: 25px 0;
            }
            span {
                border-bottom: 2px solid $orange;
            }
        }
    }
    &__item {
        width: calc(50% - 50px);
        max-width: calc(50% - 50px);
        margin: 0 25px;
        @include media(414) {
            width: 100%;
            max-width: 320px;
            height: 200px;
            margin: 0;
        }
        &:first-child {
            @include media(414) {
                margin-bottom: 40px;
            }
        }
        &:first-child,
        &:last-child {
            &:after {
                @include media(414) {
                    left: auto;
                    right: -6px;
                }
            }
        }
        &:nth-child(2) {
            &:after {
                @include media(414) {
                    left: -6px;
                    right: auto;
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            bottom: -8px;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% + 12px);
            height: 100%;
            background-color: $gray;
            border-radius: 10px;
            @include media(414) {
                transform: translateX(0);
                background-color: $orange;
                width: 80%;
                bottom: -6px;
                height: calc(100% + 12px);
            }
        }
    }
}


.vipMoves {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 50px 0;
    @include media(414) {
        margin: 20px 0 30px;
    }
    &__item {
        position: relative;
        z-index: 1;
        width: calc(50% - 35px);
        max-width: calc(50% - 35px);
        height: 300px;
        @include media(768) {
            width: calc(50% - 15px);
            max-width: calc(50% - 15px);
        }
        @include media(414) {
            width: 100%;
            max-width: 100%;
        }
        &:after {
            content: '';
            display: none;
            position: absolute;
            z-index: -1;
            left: -6px;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 10px;
            background-color: $blue;
            width: 80%;
            height: calc(100% + 12px);
        }
        &:first-child {
            @include media(414) {
                height: 220px;
            }
        }
        &:last-child {
            @include media(414) {
                height: 200px;
                max-width: 320px;
            }
            &:after {
                @include media(414) {
                    display: block;
                }
            }
        }
        &:not(:last-child) {
            margin-right: 70px;
            @include media(768) {
                margin-right: 30px;
            }
            @include media(414) {
                margin-right: 0;
                margin-bottom: 30px;
            }
        }
    }
}