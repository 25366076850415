.s-request {
    padding: 50px 0;
    background-color: $gray;
    @include media(414) {
        padding: 20px 0;
    }
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
    max-width: 550px;
    p {
        max-width: 260px;
        margin: 15px auto 0;
        text-align: center;
    }
    button {
        width: 100%;
        margin-bottom: 10px;
        span {
            font-weight: 600;
            letter-spacing: 0.03em;
        }
        & + span {
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: rgba(191, 210, 217, 0.4);
        }
    }
    &__item {
        position: relative;
        flex: 0 0 calc(50% - 13px);
        max-width: calc(50% - 13px);
        margin-right: 26px;
        height: 46px;
        padding: 0 12px;
        margin-bottom: 15px;
        background-color: $white;
        box-shadow: 0px 1px 5px #F5BA54;
        @include media(414) {
            flex: 0 0 100%;
            max-width: 300px;
            margin-right: 0;
        }
        input {
            width: 100%;
            height: 100%;
            font-weight: 400;
            color: $blue;
            &::placeholder {
                color: inherit;
                font-weight: inherit;
                font-size: inherit;
                transition: all .3s;
            }
            &:focus {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
        &:nth-child(2n) {
            margin-right: 0;
        }
    }
}

label.invalid {
    position: absolute;
    right: 10px;
    letter-spacing: 0.03em;
    top: 3px;
    font-size: 10px;
    color: red;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-text-fill-color: $blue;
    -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px $white;
    transition: 0s;     
    background-color: $white !important; 
}

.request {
    &__title {
        max-width: 500px;
        margin: 0 auto 25px;
        font-size: 20px;
        font-family: $Roboto;
        font-weight: 400;
        line-height: 24px;
        @include media(414) {
            font-size: 16px;
            line-height: 24px;
        }
        @include media(320) {
            font-size: 14px;
            line-height: 20px;
            max-width: 280px;
        }
        b {
            font-family: 'Montserrat',Arial,sans-serif;
            font-weight: 600;
        }
    }
    &-after {
        display: none;
        position: absolute;
        z-index: 1;
        right: 0;
        bottom: -50px;
        @include media(414) {
            display: block;
        }
    }
}