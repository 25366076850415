footer { flex: 0 0 auto; }
.footer {
    position: relative;
    z-index: 1;
    padding: 30px 0 70px;
    background-color: $blue;
    @include media(768) {
        padding: 30px 0 40px;
    }
    &__ps {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        &.left {
            left: 10%;
        }
        &.right {
            right: 10%;
        }
    }
    &__inner {
        display: flex;
        align-items: center;
        position: relative;
        padding: 16px 40px 16px 60px;
        width: 100%;
        margin-bottom: 30px;
        height: 300px;
        border-radius: 5px;
        background-color: $blue;
        border: 4px solid transparent;
        @include media(414) {
            position: relative;
            z-index: 1;
            flex-direction: column;
            height: auto;
            padding: 0;
            margin-bottom: 10px;
            border: none;
        }
        & > * {
            &:not(:last-child) {
                margin-right: 25px;
                @include media(414) {
                    margin-right: 0;
                    margin-bottom: 25px;
                }
            }
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -6px;
            right: -6px;
            border-radius: 7px;
            top: -6px;
            bottom: -6px;
            background: rgb(126,160,173);
            background: linear-gradient(90deg, rgba(126,160,173,1) 0%, rgba(173,194,202,1) 75%);
            @include media(414) {
                display: none;
            }
        }
    }
}

.f {
    &-address {
        position: relative;
        border: 2px solid #9CBAC6;
        padding: 30px 35px 35px;
        border-radius: 5px;
        @include media(414) {
            padding: 28px 18px 23px;
        }
        img {
            position: absolute;
            max-width: 100%;
            right: 35px;
            top: 25px;
            @include media(414) {
                right: 5px;
                top: 20px;
            }
        }
        p {
            font-size: 13px;
            line-height: 16px;
            color: #FFFFFF;
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
        &__city {
            font-size: 18px;
            line-height: 22px;
            color: #AEC3CC;
            margin-bottom: 9px;
        }
        &__phone,
        &__email {
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            a {
                color: $white;
            }
        }
        &__phone {
            margin-bottom: 3px;
        }
        &__email {
            margin-bottom: 20px;
        }
    }
    &-menu {
        & > * {
            &:not(:last-child) {
                @include media(414) {
                    margin-right: 50px;
                }
            }
           
        }
        &__item {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            transition: all .3s linear;
            color: $white;
            @include media(414) {
                font-size: 12px;
                line-height: 14px;
            }
            &:not(:last-child) {
                margin-bottom: 6px;
                @include media(414) {
                    margin-bottom: 10px;
                }
            }
            &:hover {
                color: #eee9e9;
            }
        }
        @include media(414) {
            position: relative;
            width: 100%;
            padding: 17px 5px 14px 20px;
            display: flex;
            justify-content: space-between;
            border-radius: 5px;
            background-color: $blue;
            border: 4px solid transparent;
        }
        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: -6px;
            right: -6px;
            border-radius: 7px;
            top: -6px;
            bottom: -6px;
            background: rgb(126,160,173);
            background: linear-gradient(90deg, rgba(126,160,173,1) 0%, rgba(173,194,202,1) 75%);
        }
    }
}

.copyright {
    text-align: center;
    color: $white;
    font-weight: 400;
    @include media(414) {
        font-size: 12px;
        opacity: .5;
    }
}